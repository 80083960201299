/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import { CREATE_CONFIRMATION, REMOVE_CONFIRMATION, START_CONFIRMATION_LOADING, STOP_CONFIRMATION_LOADING } from '../actions/confirmActions';

const initialState = {
  onClose: null,
  onConfirm: null,
  title: null,
  body: null,
  danger: false,
  isLoading: false,
  imageUrl: null
};

const confirmation = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONFIRMATION:
      return {
        onClose: action.onClose,
        onConfirm: action.onConfirm,
        title: action.title,
        body: action.body,
        danger: action.danger,
        imageUrl: action.imageUrl
      };
    case REMOVE_CONFIRMATION:
      return initialState;
    case START_CONFIRMATION_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STOP_CONFIRMATION_LOADING:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default confirmation;
