import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Cancel as CancelIcon, CheckCircle as CheckIcon, ErrorRounded as ErrorIcon, ExpandMore as ExpandMoreIcon, Help as HelpIcon } from '@mui/icons-material';
import cookies from '../../../../cookies';
import { setError, clearErrors } from '../../../../alerts';
import { Refresh as RefreshIcon } from '@mui/icons-material';

// Components
import { Accordion, AccordionDetails, AccordionSummary, Card, Container, Grid, Typography, CircularProgress, IconButton } from '@mui/material';
import { BackToButton, Button } from '@lexcelon/react-util';
import { DARKEST_GREY, InventoryType, INVENTORY_TYPE_STATUSES } from '@parasightsysteminc/companion-react';

import { formatString } from '../../../../util';
import { listCartridgeInventoryTypes, getSensorData, getInstrument } from '../../../../api';
import { DateTime } from 'luxon';
import MSDS from '../../../../assets/images/qr/msds.png';

export const INSTRUMENT_FUNCTIONS = {
  PRIME: 'START_PRIME',
  REPLACE: 'START_REPLACE',
  EMPTY: 'START_EMPTY',
  PRESENT_EGG_CHAMBER: 'PRESENT_EGG_CHAMBER',
  HIDE_EGG_CHAMBER: 'HIDE_EGG_CHAMBER'
};

const hrefUrl = ({ functionStr, inventoryTypeId = null, serialNumber }) => (`/instruments/${serialNumber}/in-progress?function=${functionStr}${inventoryTypeId ? `&inventoryTypeId=${inventoryTypeId}` : ''}`);

class Instrument extends Component {
  CartridgeWidget = ({ inDebugMode = false, instrument, inventoryType, index }) => {
    let status = inventoryType.getStatus();

    let testsSinceLastChange = null;
    let expirationDate = null;
    switch (inventoryType.getCartridgePosition()) {
      case 1:
        testsSinceLastChange = instrument.getTestsSinceLastChangeLine1();
        expirationDate = instrument.getExpirationDateLine1();
        break;
      case 2:
        testsSinceLastChange = instrument.getTestsSinceLastChangeLine2();
        expirationDate = instrument.getExpirationDateLine2();
        break;
      case 3:
        testsSinceLastChange = instrument.getTestsSinceLastChangeLine3();
        expirationDate = instrument.getExpirationDateLine3();
        break;
      case 4:
        testsSinceLastChange = instrument.getTestsSinceLastChangeLine4();
        expirationDate = instrument.getExpirationDateLine4();
        break;
      case 5:
        testsSinceLastChange = instrument.getTestsSinceLastChangeLine5();
        expirationDate = instrument.getExpirationDateLine5();
        break;
      case 6:
        testsSinceLastChange = instrument.getTestsSinceLastChangeLine6();
        expirationDate = instrument.getExpirationDateLine6();
    }
    let remainingTests = testsSinceLastChange == null ? 'Unknown' : Math.max(0, inventoryType.getTestCountPerUnit() - testsSinceLastChange);
    let daysRemaining = expirationDate != null ? expirationDate.diff(DateTime.now(), 'days').days : null;
    const expired = daysRemaining < 0;
    const expirationMessage = daysRemaining > 0 && daysRemaining < 5 ? `Expires in ${daysRemaining < 1 ? '< 1' : Math.floor(daysRemaining)} day${Math.floor(daysRemaining) <= 1 ? '' : 's'}` : null;

    let iconColor = 'grey';
    let Icon = HelpIcon;
    let statusMessage = formatString(status);
    if (expired) {
      iconColor = 'red';
      Icon = CancelIcon;
      statusMessage = 'Expired';
    }
    else if (status === INVENTORY_TYPE_STATUSES.GOOD) {
      iconColor = 'green';
      Icon = CheckIcon;
    }
    else if (status === INVENTORY_TYPE_STATUSES.LOW) {
      iconColor = 'orange';
      Icon = ErrorIcon;
    }
    else if (status !== INVENTORY_TYPE_STATUSES.UNKNOWN) {
      iconColor = 'red';
      Icon = CancelIcon;
    }

    let canPrime = (status === INVENTORY_TYPE_STATUSES.LOW || status === INVENTORY_TYPE_STATUSES.GOOD || status === INVENTORY_TYPE_STATUSES.NEEDS_PRIMING) && !expired;
    let canReplace = status !== INVENTORY_TYPE_STATUSES.UNKNOWN;
    let canEmpty = status !== INVENTORY_TYPE_STATUSES.UNKNOWN && status !== INVENTORY_TYPE_STATUSES.MISSING;

    return (
      <Grid key={index} item xs={4}>
        <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '400px', padding: '5%', backgroundColor: 'lightgrey', border: '2px solid grey', borderRadius: '20px' }} elevation={3}>
          <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={inventoryType.getImageUrl()} style={{ width: '100%', objectFit: 'contain' }} alt={`${inventoryType.getName()} Cartridge Icon`} />
          </div>

          <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
              <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: { xs: '18px', sm: '22px', md: '25px' }, color: iconColor }}>{statusMessage}</Typography>
            </div>
            <Icon style={{ width: '70px', height: '70px', color: iconColor }} />
            <div style={{ minHeight: '30px' }}>
              {!expired &&
              <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12, textAlign: 'center', color: 'slategrey', marginTop: '10px' }}>
                {`EST ${remainingTests}/${inventoryType.getTestCountPerUnit()} Tests Remaining`}
              </Typography>}
            </div>
            <div style={{ height: '15px' }}>
              {expirationMessage != null &&
              <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12, textAlign: 'center', color: 'red', marginTop: '2px' }}>
                {expirationMessage}
              </Typography>}
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <Button secondary style={{ width: '100%', textAlign: 'center' }} disabled={!canReplace} href={hrefUrl({ serialNumber: instrument.getSerialNumber(), functionStr: INSTRUMENT_FUNCTIONS.REPLACE, inventoryTypeId: inventoryType.getId() })}>Replace Cartridge</Button>
            <Button secondary style={{ width: '100%', marginTop: '10px', textAlign: 'center' }} disabled={!canPrime} href={hrefUrl({ serialNumber: instrument.getSerialNumber(), functionStr: INSTRUMENT_FUNCTIONS.PRIME, inventoryTypeId: inventoryType.getId() })}>Prime Cartridge</Button>
            {inDebugMode && <Button secondary style={{ width: '100%', marginTop: '10px' }} disabled={!canEmpty} href={hrefUrl({ serialNumber: instrument.getSerialNumber(), functionStr: INSTRUMENT_FUNCTIONS.EMPTY, inventoryTypeId: inventoryType.getId() })}>Empty Line</Button>}
          </div>
        </Card>
      </Grid>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoadingInstrument: true,
      sensorData: null,
      error: null,
      redirect: false,
      cartridgeInventoryTypes: null
    };
    this.hasMountedAlready = false;
  }

  componentDidMount() {
    // If in debug mode, show whatever
    // If on THE instrument, show whatever
    // if not on THE instrument or not on an instrument, redirect

    if (!cookies.getInDebugMode() && (!cookies.isInstrumentSet() || cookies.getInstrumentSerialNumber() !== this.props.match?.params?.serialNumber)) {
      setError('You must be on the instrument to view this page.');
      this.setState({ redirect: true });
      return;
    }

    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      this.retrieveSensorData();
    }
    this.hasMountedAlready = true;

    getInstrument(this.props.match?.params?.serialNumber).then((instrument) => {
      listCartridgeInventoryTypes().then((cartridgeInventoryTypes) => {
        this.setState({ cartridgeInventoryTypes, instrument, isLoadingInstrument: false }, () => {
          if (this.state.sensorData != null) this.determineCartridgeStatuses();
        });
      }).catch((error) => {
        this.setState({ error });
        setError(error ?? 'Error: Unable to retrieve cartridge inventory types');
      });
    }).catch(error => {
      this.setState({ error });
      setError(error ?? 'Error: Unable to retrieve instrument');
    });
  }

  retrieveSensorData = () => {
    this.setState({ isLoading: true });

    getSensorData(this.props.match?.params?.serialNumber)
      .then((sensorData) => {
        clearErrors();
        this.setState({ isLoading: false, error: null, sensorData }, () => {
          if (this.state.cartridgeInventoryTypes != null) this.determineCartridgeStatuses();
        });
      }).catch((error) => {
        setError(error ?? 'Unable to retrieve sensor data');
        this.setState({ isLoading: false, error });
      });
  };

  determineCartridgeStatuses = () => {
    InventoryType.updateStatusesFromSensorData(this.state.cartridgeInventoryTypes, this.state.sensorData, { ignoreCartridgeReadySensors: this.state.instrument.getIgnoreCartridgeReadySensors(), ignoreCartridgeSensors: this.state.instrument.getIgnoreCartridgeSensors() });
    // Set status of all cartridge inventory types to MISSING
    this.setState({ cartridgeInventoryTypes: this.state.cartridgeInventoryTypes });
  }

  render() {
    if (this.state.redirect) return <Redirect to='/' />;

    const instrumentSerialNumber = this.props.match?.params?.serialNumber;

    // Make a subarray of indexes 0-2 of the CARTRIDGE_LOGOS array
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}
        <div style={{ marginTop: this.props.location?.state?.backTo != null ? '-30px' : '0px' }}><BackToButton to='/tests/new' description='New Test' /></div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{ flex: 1 }}>
            <Typography variant='h1' style={{ textAlign: 'left', marginBottom: '0.5em' }}>Cartridge Status</Typography>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <IconButton style={{ backgroundColor: '#606060', color: 'white', marginLeft: '10px', opacity: this.state.isLoading ? 0.5 : 1 }} onClick={this.retrieveSensorData} disabled={this.state.isLoading}><RefreshIcon /></IconButton>
              <Typography variant='body1' style={{ marginLeft: '10px', fontSize: '23px', color: DARKEST_GREY }}>{this.state.isLoading ? 'Retrieving sensor data' : (this.state.error == null ? 'Refresh' : 'Error Retrieving Sensor Data')}{this.state.isLoading && <CircularProgress style={{ marginLeft: '5px', height: '20px', width: '20px' }} />}</Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='button' style={{ width: '100%', textAlign: 'center' }}>MSDS Sheet</Typography>
            <img src={MSDS} alt='MSDS' style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
          </div>
        </div>

        {!this.state.isLoading && this.state.error != null &&
        <>
          <Typography variant='body1'>{this.state.error.message}</Typography>
        </>}

        {this.state.cartridgeInventoryTypes?.find(inventoryType => inventoryType.getStatus() !== INVENTORY_TYPE_STATUSES.MISSING) == null && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
            <Button secondary href={hrefUrl({ serialNumber: instrumentSerialNumber, functionStr: INSTRUMENT_FUNCTIONS.REPLACE })}>Replace All Cartridges</Button>
          </div>
        )}

        {!this.state.isLoading && !this.state.isLoadingInstrument && this.state.error == null &&
        <>
          {/* Cartridge Widgets */}
          {this.state.cartridgeInventoryTypes != null &&
          <Grid container spacing={1} direction={{ xs: 'column-reverse', md: 'row' }}>
            {/* Top Row */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                {this.state.cartridgeInventoryTypes.slice(0,3).map((inventoryType, index) => (
                  this.CartridgeWidget({ inDebugMode: cookies.getInDebugMode(), instrument: this.state.instrument, inventoryType, index })
                ))}
              </Grid>
            </Grid>

            {/* Bottom Row */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                {this.state.cartridgeInventoryTypes.slice(3,6).map((inventoryType, index) => (
                  this.CartridgeWidget({ inDebugMode: cookies.getInDebugMode(), instrument: this.state.instrument, serialNumber: this.props.match?.params?.serialNumber, inventoryType, index })
                ))}
              </Grid>
            </Grid>
          </Grid>}

          {/* Additional Inventory */}
          <Typography variant='h2' style={{ marginTop: '1em', textAlign: 'center' }}>If you need additional cartridges, please contact support at <br />833-233-7278.</Typography>

          {/* Egg Chamber Buttons */}
          <Accordion style={{ marginTop: '2em' }} elevation={3} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: 'lightgrey' }}>
              <Typography style={{ fontWeight: 'bold' }}>Egg Chamber</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '1em' }}>
              <Grid container spacing={1} alignItems='center' justifyContent='center'>
                <Grid item>
                  <Button secondary href={hrefUrl({ serialNumber: instrumentSerialNumber, functionStr: INSTRUMENT_FUNCTIONS.PRESENT_EGG_CHAMBER })}>Present Egg Chamber</Button>
                </Grid>
                <Grid item>
                  <Button secondary href={hrefUrl({ serialNumber: instrumentSerialNumber, functionStr: INSTRUMENT_FUNCTIONS.HIDE_EGG_CHAMBER })}>Hide Egg Chamber</Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Buttons */}
          <Accordion style={{ marginTop: '2em' }} elevation={3}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: 'lightgrey' }}>
              <Typography style={{ fontWeight: 'bold' }}>Additional Options</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '1em' }}>
              <Grid container spacing={1} alignItems='center' justifyContent='center'>
                <Grid item>
                  <Button secondary href={hrefUrl({ serialNumber: instrumentSerialNumber, functionStr: INSTRUMENT_FUNCTIONS.REPLACE })}>Replace All Cartridges</Button>
                </Grid>
                <Grid item>
                  <Button secondary href={hrefUrl({ serialNumber: instrumentSerialNumber, functionStr: INSTRUMENT_FUNCTIONS.PRIME })}>Prime All Cartridges</Button>
                </Grid>
                <Grid item>
                  <Button secondary href={hrefUrl({ serialNumber: instrumentSerialNumber, functionStr: INSTRUMENT_FUNCTIONS.EMPTY })}>Empty All Lines</Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* DEBUG MODE ONLY Sensor Data */}
          {cookies.getInDebugMode() && this.state.sensorData != null &&
          <div style={{ marginTop: '2em' }}>
            <Typography variant='h2'>Raw Sensor Data</Typography>
            <Typography variant='subtitle2' style={{ color: 'slategrey' }}>*Most recent data listed first</Typography>
            {Object.keys(this.state.sensorData)?.map((key, index) => {
              let valueString = this.state.sensorData[key]?.join(', ');
              return (
                <Accordion key={index} style={{ marginTop: '1em', backgroundColor: 'lightgrey' }} defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h4'>{key}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='body1'>{valueString}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>}
        </>}
      </Container>
    );
  }
}

Instrument.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Instrument);
