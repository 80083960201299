import { createTheme } from '@mui/material/styles';

// Constants
import { LIGHTEST_GREEN, DARK_GREEN, DARKER_GREEN, WHITE, GREY, LIGHTER_GREY, DARKEST_GREY } from '@parasightsysteminc/companion-react';

// https://material-ui.com/customization/palette/
export default createTheme({
  palette: {
    primary: {
      main: DARK_GREEN,
      light: LIGHTEST_GREEN,
      dark: DARKER_GREEN,
      contrastText: WHITE
    },
    secondary: {
      main: GREY,
      light: LIGHTER_GREY,
      dark: DARKEST_GREY,
      contrastText: WHITE
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    h1: {
      fontSize: 50
    },
    h2: {
      fontSize: 38
    },
    h3: {
      fontSize: 32
    },
    h4: {
      fontSize: 26
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          boxSizing: 'border-box',
          margin: 0
        }
      }
    }
  }
});
