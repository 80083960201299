import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { setError } from '../../alerts';
import cookies from '../../cookies';

// Components
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listVeterinarians } from '../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

// Constants
import { VETERINARIAN_TITLES } from '@parasightsysteminc/companion-react';

class Veterinarians extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: this.props.location?.state?.tableSearchString ?? null
    };
  }

  render() {
    const locationState = { backTo: { pathname: { pathname: '/veterinarians', state: { tableSearchString: this.state.searchString } }, description: 'All Veterinarians' } };
    const COLUMNS = [
      {
        title: 'First Name',
        remoteField: 'firstName',
        value: rowData => rowData.getFirstName(),
        search: 'column'
      },
      {
        title: 'Last Name',
        remoteField: 'lastName',
        value: rowData => rowData.getLastName(),
        search: 'column'
      },
      {
        title: 'Email',
        remoteField: 'email',
        value: rowData => rowData.getEmail(),
        search: 'column'
      },
      {
        title: 'Title',
        remoteField: 'title',
        value: rowData => rowData.getTitle(),
        search: 'select',
        selectOptions: VETERINARIAN_TITLES.map(value => ({ value })),
      },
      {
        title: 'Actions',
        value: () => '',
        render: (rowData) => (
          <Link to={{ pathname: '/veterinarians/' + rowData.getId(), state: locationState }}><VisibilityIcon /></Link>
        ),
        omitFromExport: true
      }
    ];
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Veterinarians</Typography>

        <MaterialTable
          title='Veterinarians'
          headerContent={
            <Button component={Link} to={{ pathname: '/veterinarians/create', state: locationState }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listVeterinarians({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          options={{
            hideExport: cookies.isInstrumentSet() ? true : false,
            flipHorizontalScroll: true,
            pageSize: 20,
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />
      </div>
    );
  }
}

Veterinarians.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Veterinarians);
