import Cookies from 'js-cookie';
import { clearErrors, setAlert, setSuccess } from './alertActions';

// API
import { getPractice, login, logout } from '../../api';

// Constants
export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const AUTHENTICATED = 'AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const PASSWORD_RESET = 'PASSWORD_RESET';

const COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;

// Actions
export const startLoading = () => ({
  type: IS_LOADING
});

export const stopLoading = () => ({
  type: IS_NOT_LOADING
});

export const passwordReset = () => ({
  type: PASSWORD_RESET
});

export const authenticated = (passwordResetRequired, logoUrl) => ({
  type: AUTHENTICATED,
  passwordResetRequired,
  logoUrl
});

export const notAuthenticated = () => ({
  type: NOT_AUTHENTICATED
});

/* ----- AUTHENTICATE ----- */

export const authenticateUser = (dispatch, email = null, password = null) => {
  dispatch(startLoading());

  login({ email, password }).then(user => {
    dispatch(clearErrors());
    dispatch(authenticated(user.getPasswordResetRequired(), user.getPractice()?.getLogoUrl()));
  }).catch(error => {
    setAlert(dispatch, error);
    dispatch(notAuthenticated());
  });
};

export const checkAuthentication = (dispatch, isAuthenticated) => {
  dispatch(startLoading());

  // If we falsely have the user logged in, log them out and clear all cookies.
  if ((Cookies.get(COOKIE_NAME) === undefined && isAuthenticated)) {
    logoutUser(dispatch);
  }

  else if (Cookies.get(COOKIE_NAME) !== undefined && !isAuthenticated) {
    // If both cookies are there, fully authenticate
    getPractice().then(practice => {
      dispatch(authenticated(false, practice.getLogoUrl()));
    }).catch(() => {
      dispatch(notAuthenticated());
    });
  }

  else {
    dispatch(stopLoading());
  }
};

export const logoutUser = (dispatch) => {
  // Revoke tokens and unauthenticate in redux state
  if (Cookies.get(COOKIE_NAME) !== undefined) {
    logout().then(() => {
      setSuccess('Successfully logged out.');
      clearCookies(dispatch);
    }).catch(() => {
      clearCookies(dispatch);
    });
  }
  else clearCookies(dispatch);
};

export const clearCookies = (dispatch) => {
  Cookies.remove(COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  dispatch(notAuthenticated());
};
