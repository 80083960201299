import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lexcelon/react-util';
import { Grid, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { LabTechForm } from '../../components';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

class LabTechDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  setOpen = () => {
    this.setState({ open: true });
  }

  setClose = () =>  {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.setClose}>
          <Grid item 
            sx={{
              display: { xs: 'none', md: 'block' }
            }}>
            <Button onClick={this.setOpen} style={{ minWidth: '100%', height: '4em' }} disabled={this.props.disabled ?? false}>
              Create New Lab Tech +
            </Button>
          </Grid>
          <Grid item sx={{
            display: { xs: 'block', md: 'none' }
          }}>
            <div style={{ height: '3.5em', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
              <IconButton onClick={this.setOpen} style={{ minWidth: '0px' }}><AddIcon /></IconButton>
            </div>
          </Grid>
          <Dialog open={this.state.open} onClose={this.setClose}>
            <DialogTitle>Create New Lab Tech</DialogTitle>
            <DialogContent>
              <LabTechForm onSuccess={this.props.onSuccess} onClose={this.setClose}></LabTechForm>
            </DialogContent>
          </Dialog>
        </form>
      </div>
    );
  }
}

LabTechDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default LabTechDialog;