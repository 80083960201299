import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { LIGHTEST_GREEN } from '@parasightsysteminc/companion-react';

var RadioGroupContext = createContext();

class RadioGroup extends Component {
  render() {
    return (
      <RadioGroupContext.Provider value={{ selectedValue: this.props.selectedValue, onSelection: this.props.onSelection }}>
        <Grid container style={{ width: '100%', ...this.props.style }} columnSpacing={5} rowSpacing={2}>
          {this.props.children}
        </Grid>
      </RadioGroupContext.Provider>
    );
  }
}

function Item({ children, value, style, disabled }) {
  var context = useContext(RadioGroupContext);
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box
        style={{
          width: '100%',
          height: '100px',
          padding: '10px',
          border: `3px solid ${disabled ? '#AAAAAA' : context.selectedValue === value ? LIGHTEST_GREEN : 'grey'}`,
          backgroundColor: disabled ? '#AAAAAA' : context.selectedValue === value ? LIGHTEST_GREEN : 'transparent',
          borderRadius: '10px',
          opacity: disabled ? 0.3 : 1,
          cursor: disabled ? 'auto' : 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ...style
        }} onClick={disabled ? () => null : () => context.onSelection(value)}>
        {children}
      </Box>
    </Grid>
  );
}

RadioGroup.propTypes = {
  style: PropTypes.object,
  children: PropTypes.array,
  selectedValue: PropTypes.any,
  onSelection: PropTypes.func
};

Item.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  style: PropTypes.object,
  disabled: PropTypes.bool
};

RadioGroup.Item = Item;
export default RadioGroup;
