import Cookies from 'js-cookie';

const getInDebugMode = () => Cookies.get('inDebugMode') != null;
const setInDebugMode = (value) => {
  if (value) Cookies.set('inDebugMode', 'true', { expires: 1/24 });
  else Cookies.remove('inDebugMode');
};

const getInstrumentSerialNumber = () => Cookies.get('instrumentSerialNumber');
const setInstrumentSerialNumber = (value) => Cookies.set('instrumentSerialNumber', value);
const isInstrumentSet = () => Cookies.get('instrumentSerialNumber') != null;
const clearInstrument = () => Cookies.remove('instrumentSerialNumber');

export default {
  getInDebugMode,
  setInDebugMode,
  getInstrumentSerialNumber,
  setInstrumentSerialNumber,
  isInstrumentSet,
  clearInstrument
};