import React from 'react';
import { Redirect } from 'react-router-dom';

export default function AuthRedirect(pathname, authProps) {
  const { isLoading, isAuthenticated } = authProps;
  pathname = pathname.toLowerCase();

  if (!isLoading) {
    if (isAuthenticated) {
      if (pathname.indexOf('/auth') === 0) {
        return <Redirect to='/' />;
      }
    }

    // Any other non-auth page
    else if (!isAuthenticated && pathname.indexOf('/auth') !== 0) return <Redirect to='/auth/login' />;
  }
  return null;
}
