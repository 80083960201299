import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

// Components
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import VeterinarianForm from '../../../components/forms/VeterinarianForm';

class CreateVeterinarian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToVetId: null
    };
  }

  render() {
    return this.state.redirectToVetId != null ? <Redirect to={'/veterinarians/' + this.state.redirectToVetId} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Create Veterinarian</Typography>
        <VeterinarianForm onSuccess={(veterinarian) => this.setState({ redirectToVetId: veterinarian?.getId() })} />
      </Container>
    );
  }
}

CreateVeterinarian.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreateVeterinarian);
