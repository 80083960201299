import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cookies from '../../cookies';
import { setError } from '../../alerts';
import { Link, withRouter } from 'react-router-dom';

// Icons
import { Visibility as VisibilityIcon, Add as AddIcon } from '@mui/icons-material/';

// Components
import { Typography } from '@mui/material';
import { BackToButton, Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listInstruments, getPractice } from '../../api';

// Icons
import { DateTime } from 'luxon';

class Instruments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      practice: null,
      searchString: this.props.location?.state?.tableSearchString ?? null
    };
  }

  componentDidMount() {
    getPractice().then(practice => {
      this.setState({ practice });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve practice. Please try again.');
    });
  }

  render() {
    const locationState = { backTo: { pathname: { pathname: '/instruments', state: { tableSearchString: this.state.searchString } }, description: 'All Instruments' } };
    const COLUMNS = [
      {
        title: 'Display Name',
        remoteField: 'friendlyName',
        value: rowData => rowData.getFriendlyName() || '(None Set)',
        search: 'column'
      },
      {
        title: 'Serial Number',
        remoteField: 'serialNumber',
        value: rowData => rowData.getSerialNumber(),
        search: 'column'
      },
      {
        title: 'Last Seen',
        remoteField: 'lastSeenTimestamp',
        value: rowData => rowData.getLastSeenTimestamp()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short', timeZone: this.state.practice?.getAddress()?.getTimeZone() ?? undefined }),
        search: 'date'
      },
      {
        title: 'Actions',
        value: () => '',
        render: (rowData) => (
          <Link to={{ pathname: '/instruments/' + rowData.getSerialNumber(), state: locationState }}><VisibilityIcon /></Link>
        )
      }
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Your Instruments</Typography>

        <MaterialTable
          title={cookies.getInDebugMode() ? (
            <Button component={Link} to={{ pathname: '/instruments/setup', state: locationState }}>
              Setup New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          ) : null}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listInstruments({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          options={{
            hideExport: cookies.isInstrumentSet() ? true : false,
            flipHorizontalScroll: true
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />
      </div>
    );
  }
}

Instruments.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Instruments);
