import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lexcelon/react-util';
import { CircularProgress, IconButton, InputAdornment, DialogActions, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { validateDebugPassword } from '../../api';
import cookies from '../../cookies';
import { setError, setSuccess } from '../../alerts';
import { Visibility, VisibilityOff } from '@mui/icons-material';

class DebugModeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      isLoading: false,
      showPassword: false
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    validateDebugPassword(this.state.password).then(() => {
      // Turn off automatically after 1 hour
      cookies.setInDebugMode(true);
      setSuccess('Debug mode on for one hour! Please make sure to turn it off when you are done.');
      this.setState({ isLoading: false });
      window?.location?.reload(false);
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error ?? 'Error: Unable to turn on debug mode. Please try again.');
    });
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={this.props.onClose}>
        <DialogTitle>Turn On Debug Mode</DialogTitle>
        <DialogContent>
          <form onSubmit={this.onSubmit}>
            <TextField
              required
              name='password'
              label='Password'
              value={this.state.password}
              onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
              variant='filled'
              disabled={this.state.isLoading}
              style={{ width: '100%', marginBottom: '0.5em' }}
              type={this.state.showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <DialogActions>
              <Button secondary onClick={this.props.onClose} disabled={this.state.isLoading}>Cancel</Button>
              <Button type='submit' disabled={this.state.isLoading}>Submit {this.state.isLoading && <CircularProgress size={20} style={{ marginLeft: '10px' }} />}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

DebugModeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DebugModeDialog;