/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

// Redux
import { connect } from 'react-redux';
import { removeAlert } from '../redux/actions/alertActions';
import ParryImage from '../assets/images/parry.png';

class AlertList extends Component {
  render() {
    return (
      <>
        {this.props.underConstruction != null && (
          <Alert severity='info' onClose={null}>
            {this.props.underConstruction}
          </Alert>
        )}
        {this.props.banner != null && (
          <Alert
            icon={<img src={ParryImage} alt='Parry the Parasite' style={{ width: 50, height: 50, objectFit: 'contain' }} />}
            severity='error'
            onClose={null}
            style={{ alignItems: 'center', color: 'black', fontWeight: 'bold' }}>
            {this.props.banner}
          </Alert>
        )}
        {this.props.alerts.map((alert, index) => (
          <Alert key={index} severity={alert.severity} onClose={() => this.props.removeAlert(alert.id)}>
            {alert.msg}
          </Alert>
        ))}
      </>
    );
  }
}

AlertList.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired,
  underConstruction: PropTypes.string,
  banner: PropTypes.string
};

const mapStateToProps = state => ({
  underConstruction: state.alerts.underConstruction,
  banner: state.alerts.banner,
  alerts: state.alerts.alerts
});

const mapDispatchToProps = dispatch => ({
  removeAlert: id => dispatch(removeAlert(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertList);
