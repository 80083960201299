import React, { Component } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { setError } from '../../../alerts';

import { LineItem, Button } from '@lexcelon/react-util';

// Api
import { countTests } from '../../../api';

// Date/Time UI
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';


class TestsSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      practice: null,
      isLoading: false,
      startDate: null,
      endDate: null,
      dateRangeString: 'Total Count for – to –',
      startDatePickerOpen: false,
      endDatePickerOpen: false
    };
  }

  onStartDateChange = (newStartDate) => {
    let endDate = this.state.endDate;
    if (newStartDate > endDate) endDate = newStartDate;
    this.setState({ startDate: newStartDate, endDate });
  }
  onEndDateChange = (newEndDate) => {
    this.setState({ endDate: newEndDate });
  }

  componentDidMount() {
    this.retrievePractice();
  }

  retrievePractice = () => {
    // get tests from API
    this.setState({ isLoading: true });
    countTests(this.state.startDate?.startOf('day').toISO(), this.state.endDate?.endOf('day').toISO()).then((results) => {
      this.setState({ practice: results });
      this.setState({ isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to load practice information. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onDateRangeApply = (e) => {
    e.preventDefault();

    this.retrievePractice();
    this.setState({ dateRangeString: 'Total count for ' + (this.state.startDate?.toLocaleString() ?? '–') + ' to ' + (this.state.endDate?.toLocaleString() ?? '–') });
  }

  render() {
    return (
      <Container>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>{this.state.practice?.getName()} Tests Summary</Typography>

        <LineItem
          value={this.state.practice?.getTestCount()}
          description='Total count for all time'
        />
        <LineItem
          value={this.state.practice?.getMonthTestCount()}
          description={'Total count for ' + DateTime.now().toFormat('MMMM yyyy')}
        />

        <Typography variant='h4' style={{ marginTop: '1.5em', marginBottom: '0.5em' }}>Custom Date Range</Typography>

        <form onSubmit={this.onDateRangeApply}>
          <Grid container direction='row' align-items='flex-start' spacing={2}>
            <Grid item xs={12} sm='auto'>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <StaticDatePicker
                  label='Start Date'
                  value={this.state.startDate}
                  onChange={this.onStartDateChange}
                  disabled={this.state.isLoading}
                  disableFuture
                  slots={{
                    actionBar: () => null,
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm='auto'>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <StaticDatePicker
                  label='End Date'
                  value={this.state.endDate}
                  onChange={this.onEndDateChange}
                  minDate={this.state.startDate}
                  disabled={this.state.isLoading}
                  disableFuture
                  slots={{
                    actionBar: () => null,
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Button type='submit' isLoading={this.state.isLoading} style={{ height: '100%', width: '100%' }} disabled={this.state.startDate == null || this.state.endDate == null || this.state.startDate > this.state.endDate}>
            Apply Date Range
          </Button>
        </form>

        <div style={{ padding: '1em', backgroundColor: '#EEEEEE', borderRadius: '15px', marginTop: '20px' }}>
          <LineItem
            value={this.state.practice?.getRangeTestCount()}
            description={this.state.dateRangeString}
          />
        </div>
      </Container >
    );
  }
}

TestsSummary.propTypes = {
};

export default TestsSummary;
