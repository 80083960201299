import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lexcelon/react-util';
import { Dialog, DialogContent, Typography } from '@mui/material';

class AlertDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    if (this.props.message != null) this.setState({ isOpen: true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message && this.props.message != null) {
      this.setState({ isOpen: true });
    }
  }

  onClose = () =>  {
    this.setState({ isOpen: false });
    this.props.onClose();
  }

  render() {
    return (
      <Dialog open={this.state.isOpen} onClose={this.onClose}>
        <DialogContent style={{ backgroundColor: this.props.type === 'ERROR' ? 'red' : 'green', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography style={{ color: 'white', textAlign: 'center' }}>{this.props.message}</Typography>
          <Button onClick={this.onClose} style={{ marginTop: '10px', borderColor: 'white', color: 'white' }} outlined>OK</Button>
        </DialogContent>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf['ERROR', 'SUCCESS'],
  onClose: PropTypes.func.isRequired
};

export default AlertDialog;