import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BackToButton } from '@lexcelon/react-util';
import { Container, TextField, Typography } from '@mui/material';
import cookies from '../../../cookies';

// API
import { activateInstrument, getInstrument } from '../../../api';

// Alerts
import { setSuccess, setError } from '../../../alerts';

// Components
import { Button } from '@lexcelon/react-util';

class InstrumentSetup extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      activationCode: cookies.getInstrumentSerialNumber() ?? '',
      friendlyName: '',
      redirect: false,
      isLoading: false
    };
  }

  componentDidMount() {
    // If not in debug mode OR instrument is not set OR instrument is already activated, redirect to home
    if (!cookies.getInDebugMode() && !cookies.isInstrumentSet()) {
      setError('Error: Not on an instrument');
      this.setState({ redirect: true });
    }

    else if (!cookies.getInDebugMode()) {
      getInstrument(cookies.getInstrumentSerialNumber()).then(() => {
        setError('Error: Instrument is already activated');
        this.setState({ redirect: true });
      }).catch(error => {
        // Do nothing
        console.log(error);
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    activateInstrument(this.state.activationCode, this.state.friendlyName).then(() => {
      setSuccess('Successfully activated instrument!');
      this.setState({ redirect: true, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to activate instrument. Please make sure the instrument is connected to the internet and try again.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return this.state.redirect ? <Redirect to='/' /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Instrument Setup</Typography>

        <Typography variant='body1' style={{ textAlign: 'center', marginBottom: '1em' }}>Please plug your instrument into power, power on the device, and follow the instructions to connect the instrument to your network via WiFi or ethernet cable. Enter the activation code displayed on the instrument below.</Typography>

        <form onSubmit={this.onSubmit}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField required style={{ width: '300px' }} label="Activation Code (Case Sensitive)" disabled={cookies.isInstrumentSet() && !cookies.getInDebugMode()} value={this.state.activationCode} onChange={(e) => this.setState({ activationCode: e.target.value })} variant="outlined"  />
            <TextField required style={{ width: '300px', marginTop: '1em' }} label="Friendly Name" onChange={(e) => this.setState({ friendlyName: e.target.value })} variant="outlined"  />
            <Button style={{ marginTop: '1em' }} type='submit' isLoading={this.state.isLoading}>Begin Setup</Button>
          </div>
        </form>
      </Container>
    );
  }
}

InstrumentSetup.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(InstrumentSetup);
