import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import CustomerServiceImage from '../../assets/images/customer-service.png';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { LIGHT_GREEN } from '@parasightsysteminc/companion-react';
import { Button } from '@lexcelon/react-util';
import { PopupModal } from 'react-calendly';

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '0.25em' }}>Customer Support</Typography>
        <Typography variant='subtitle' style={{ textAlign: 'center', fontSize: '25px', color: 'slategrey' }}>Available M-F 8AM - 7PM EST</Typography>
        <Typography variant='subtitle' style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '25px', color: 'slategrey', marginBottom: '1em' }}>{'(excluding holidays)'}</Typography>

        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ height: 50, width: 50, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: LIGHT_GREEN }}>
              <PhoneIcon style={{ color: 'white' }} />
            </div>
            <Typography variant='body' style={{ fontSize: 22, marginLeft: 5 }}>833-233-7278</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 50, width: 50, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: LIGHT_GREEN }}>
              <MailIcon style={{ color: 'white' }} />
            </div>
            <Typography variant='body' style={{ fontSize: 22, marginLeft: 5 }}>support@parasightsystem.com</Typography>
          </div>
        </div>

        <Button onClick={() => this.setState({ isModalOpen: true })} style={{ width: '100%', height: 50, marginTop: 20 }}>Schedule a Support Meeting</Button>

        <PopupModal
          url='https://calendly.com/mepequinesolutions-support/support-meeting'
          text='Schedule a Support Meeting'
          rootElement={document.getElementById('root')}
          open={this.state.isModalOpen}
          onModalClose={() => this.setState({ isModalOpen: false })}
        />

        <img src={CustomerServiceImage} alt='Smiling woman at desk' style={{ width: '500px' }} />
        <Typography variant='body' style={{ fontSize: 22, color: 'slategrey', textAlign: 'center', marginTop: '1em' }}>https://www.parasightsystem.com/patent/</Typography>
      </Container>
    );
  }
}

ContactUs.propTypes = {
};

export default ContactUs;
