import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import Telasight from '../../assets/images/telasight.png';
import { Button } from '@lexcelon/react-util';
import { PopupModal } from 'react-calendly';

class Consult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} center>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Consult a Parasitologist</Typography>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Telasight} alt='Telasight logo' style={{ width: '600px', objectFit: 'contain' }} />
        </div>

        <Typography variant='h2' style={{ textAlign: 'center' }} gutterBottom>Schedule a Consultation</Typography>

        <Button onClick={() => this.setState({ isModalOpen: true })} style={{ width: '100%', height: 50 }}>Schedule</Button>

        <PopupModal
          url='https://calendly.com/consultation-mepequinesolutions/30min'
          text='Schedule a Consultation'
          rootElement={document.getElementById('root')}
          open={this.state.isModalOpen}
          onModalClose={() => this.setState({ isModalOpen: false })}
        />
      </Container>
    );
  }
}

Consult.propTypes = {
};

export default Consult;
