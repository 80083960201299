import React, { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

function ScrollToBottomButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 20
      ) {
        setIsVisible(false);
      }
      else {
        setIsVisible(true);
      }
    }
    window.addEventListener('scroll', handleScroll);

    // Use ResizeObserver to trigger handleScroll whenever the DOM changes
    const resizeObserver = new ResizeObserver(handleScroll);
    resizeObserver.observe(document.body);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      resizeObserver.disconnect();
    };
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const buttonStyle = {
    display: isVisible ? 'flex' : 'none',
    position: 'fixed',
    width: '25px',
    height: '25px',
    top: '0px',
    right: '0px',
    zIndex: '99',
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    backgroundColor: '#888',
    color: 'white',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <>
      <button
        onClick={scrollToBottom}
        title="Scroll to Bottom"
        style={buttonStyle}
      >
        <KeyboardDoubleArrowDownIcon />
      </button>
    </>
  );
}

export default ScrollToBottomButton;