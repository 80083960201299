import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { PasswordChangeForm } from '../../../components';
import { connect } from 'react-redux';

// Alerts
import { setSuccess } from '../../../alerts';
import { passwordReset } from '../../../redux/actions/authActions';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToHome: false
    };
  }

  onPasswordChangeSuccess = () => {
    setSuccess('Successfully changed password!');
    this.props.resetPassword();
    this.setState({ redirectToHome: true });
  }

  render() {
    return this.state.redirectToHome ? <Redirect to='/' /> : (
      <Container style={{ marginTop: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em' }}>Reset Password</Typography>
        <Typography variant='body1' style={{ marginBottom: '1em' }}>Please update your password for security purposes.</Typography>
        <PasswordChangeForm
          onSuccess={this.onPasswordChangeSuccess}
          reset={true}
        />
      </Container>
    );
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  resetPassword: () => dispatch(passwordReset())
});

export default connect(undefined, mapDispatchToProps)(ResetPassword);
