import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';

import { AnimalForm } from '../../../components';

class CreateAnimal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToAnimalId: null
    };
  }

  render() {
    return this.state.redirectToAnimalId != null ? <Redirect to={'/animals/' + this.state.redirectToAnimalId} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Create Patient</Typography>

        <AnimalForm
          onSuccess={(animal) => this.setState({ redirectToAnimalId: animal?.getId() })}
        />
      </Container>
    );
  }
}

CreateAnimal.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreateAnimal);
