import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Pages
import Account from '../pages/account';
import ResetPassword from '../pages/account/reset-password';
import Practice from '../pages/account/practice';
import PracticeInventory from '../pages/account/practice/inventory';
import PracticeInvoiceCounts from '../pages/account/practice/invoice-counts';

import CreateAnimal from '../pages/animals/create';
import Animal from '../pages/animals/[id]';
import Animals from '../pages/animals';

import ForgotPasswordConfirm from '../pages/auth/forgot-password/confirm/[token]';
import ForgotPasswordRequest from '../pages/auth/forgot-password/request';
import Login from '../pages/auth/login';

import Consult from '../pages/consult';

import ContactUs from '../pages/contact-us';

import InstrumentSetup from '../pages/instruments/setup';
import Instruments from '../pages/instruments';
import Instrument from '../pages/instruments/[serialNumber]';
import InstrumentStatus from '../pages/instruments/[serialNumber]/status';
import InstrumentInProgress from '../pages/instruments/[serialNumber]/in-progress';

import CreateLabTech from '../pages/lab-techs/create';
import LabTech from '../pages/lab-techs/[id]';
import LabTechs from '../pages/lab-techs';

import TestsSummary from '../pages/tests/summary';
import NewTest from '../pages/tests/new';
import Test from '../pages/tests/[id]';
import TestInProgress from '../pages/tests/[id]/in-progress';
import Tests from '../pages/tests';

import CreateTreatment from '../pages/treatments/create';
import Treatment from '../pages/treatments/[id]';
import Treatments from '../pages/treatments';

import Tutorials from '../pages/tutorials';

import CreateVeterinarian from '../pages/veterinarians/create';
import Veterinarian from '../pages/veterinarians/[id]';
import Veterinarians from '../pages/veterinarians';
import AuthRedirect from './AuthRedirect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeRedirect from './HomeRedirect';

class RouteAndWrapper extends Component {
  render() {
    var authRedirect = AuthRedirect(this.props.location.pathname, this.props.authProps);
    if (authRedirect != null) return authRedirect;

    else if (this.props.render != null) return (
      <Route path={this.props.path} exact={this.props.exact} render={this.props.render} />
    );

    return (
      <Route path={this.props.path} exact={this.props.exact}>
        <div style={{ paddingBottom: this.props.path !== '/tests/:id/in-progress' ? '120px' : undefined }}>
          {this.props.children}
        </div>
      </Route>
    );
  }
}

RouteAndWrapper.propTypes = {
  children: PropTypes.any,
  render: PropTypes.func,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  location: PropTypes.object.isRequired,
  authProps: PropTypes.exact({
    isLoading: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    passwordResetRequired: PropTypes.bool
  }).isRequired
};

const routeAndWrapperMapStateToProps = state => ({
  authProps: state.auth
});

const ConnectedRouteAndWrapper = connect(routeAndWrapperMapStateToProps, null)(withRouter(RouteAndWrapper));

export default class Navigation extends Component {
  render() {
    return (
      <div>
        <Switch>
          <ConnectedRouteAndWrapper path='/account/practice/inventory'>
            <PracticeInventory />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account/practice/invoice-counts'>
            <PracticeInvoiceCounts />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account/practice'>
            <Practice />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account/reset-password'>
            <ResetPassword />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/account'>
            <Account />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/animals/create'>
            <CreateAnimal />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/animals/:id'>
            <Animal />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/animals'>
            <Animals />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/auth/forgot-password/confirm/:token'>
            <ForgotPasswordConfirm />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/forgot-password/request'>
            <ForgotPasswordRequest />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/auth/login'>
            <Login />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/consult'>
            <Consult />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/contact-us'>
            <ContactUs />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/tutorials'>
            <Tutorials />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/instruments/setup'>
            <InstrumentSetup />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/in-progress' render={props => (
            <InstrumentInProgress key={props.location.search} {...props} />
          )} />

          {/* Redirect to the status page if the user tries to access the cartridges page */}
          <Route path='/instruments/:serialNumber/cartridges' render={props => (
            <Redirect to={`/instruments/${props.match.params.serialNumber}/status`} />
          )} />
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber/status'>
            <InstrumentStatus />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments/:serialNumber'>
            <Instrument />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/instruments'>
            <Instruments />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/lab-techs/create'>
            <CreateLabTech />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/lab-techs/:id'>
            <LabTech />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/lab-techs'>
            <LabTechs />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/tests/summary' >
            <TestsSummary />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/new' >
            <NewTest />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/:id/in-progress' >
            <TestInProgress />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests/:id' >
            <Test />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/tests' >
            <Tests />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/treatments/create'>
            <CreateTreatment />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/treatments/:id'>
            <Treatment />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/treatments'>
            <Treatments />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/veterinarians/create'>
            <CreateVeterinarian />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/veterinarians/:id'>
            <Veterinarian />
          </ConnectedRouteAndWrapper>
          <ConnectedRouteAndWrapper path='/veterinarians'>
            <Veterinarians />
          </ConnectedRouteAndWrapper>

          <ConnectedRouteAndWrapper path='/'>
            <HomeRedirect />
          </ConnectedRouteAndWrapper>
        </Switch>
      </div>
    );
  }
}
