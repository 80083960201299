import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import cookies from '../../../../cookies';

import { InventoryWidget } from '../../../../components';

// Alerts
import { setError } from '../../../../alerts';

// APIs
import { getPractice } from '../../../../api';

class PracticeInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      practice: null
    };
  }

  componentDidMount() {
    getPractice().then(practice => {
      this.setState({ practice });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve practice name.');
    });
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}
        {cookies.isInstrumentSet() &&
        <BackToButton to='/tests/new' description='Start New Test' />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>{this.state.practice != null ? this.state.practice.getName() + '\'s ' : ''}Consigned Inventory</Typography>

        <InventoryWidget />
      </Container>
    );
  }
}

PracticeInventory.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(PracticeInventory);
