import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// API
import { getLabTech, deactivateLabTech } from '../../../api';

// Components
import { CircularProgress, Container, Typography } from '@mui/material';
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';
import { LabTechForm } from '../../../components';

// Alerts
import { setError, setSuccess } from '../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

class LabTech extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      labTech: null,
      inEditMode: false,
      redirectToLabTechs: false
    };
  }

  componentDidMount() {
    this.retrieveLabTech();
  }

  retrieveLabTech = () => {
    // Retrieve the lab tech id from the url
    const labTechId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getLabTech(labTechId).then(labTech => {
      this.setState({ isLoading: false, labTech });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve lab tech info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  openEditMode = () => {
    this.setState({ inEditMode: true });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false });
  }

  onSuccess = () => {
    this.retrieveLabTech();
    this.closeEditMode();
  }

  deactivateConfirm = () => {
    confirm({
      title: `Are you sure you want to deactivate the lab tech ${this.state.labTech.getFirstName()} ${this.state.labTech.getLastName()}?`,
      body: 'You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        deactivateLabTech(this.state.labTech?.getId()).then((labTech) => {
          setSuccess('Successfully deactivated lab tech!');
          this.setState({ labTech, redirectToLabTechs: true });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to deactivate lab tech.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  render() {
    return this.state.redirectToLabTechs ? (
      <Redirect to={this.props.location?.state?.backTo?.pathname ?? '/lab-techs/'} />
    ) : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography
          variant='h1'
          style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
          Lab Tech{this.state.labTech != null ? ': ' + this.state.labTech.getLastName() : ''}
        </Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          this.state.inEditMode ? (
            <LabTechForm
              onSuccess={this.onSuccess}
              onClose={this.closeEditMode}
              labTech={this.state.labTech}
            />
          ) : (
            <>
              <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Personal Information</Typography>
              <>
                <LineItem
                  value={this.state.labTech?.getFirstName()}
                  description='First Name'
                />

                <LineItem
                  value={this.state.labTech?.getLastName()}
                  description='Last Name'
                />

                <LineItem
                  value={this.state.labTech?.getEmail()}
                  description='Email'
                />

                <LineItem
                  value={this.state.labTech?.getTitle()}
                  description='Title'
                />
              </>

              <div style={{ marginTop: '50px' }}>
                <Button secondary onClick={this.openEditMode}>Edit Lab Tech</Button>
              </div>

              {(this.state.labTech?.getIsActive()) &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={this.deactivateConfirm}>Suspend Lab Tech</Button>
                </div>
              }
            </>
          )
        )
        }
      </Container>
    );
  }
}

LabTech.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(LabTech);
