import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { setError } from '../../alerts';
import cookies from '../../cookies';

// Components
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listAnimals, listAnimalSpecies } from '../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

class Animals extends Component {

  constructor(props) {
    super(props);

    this.state = {
      speciesOptions: [],
      searchString: this.props.location?.state?.tableSearchString ?? null,
    };
  }

  componentDidMount() {
    // get animal species from API
    this.setState({ isLoadingSpecies: true });
    listAnimalSpecies().then((results) => {
      this.setState({ speciesOptions: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve species. Please try again.');
    });
  }

  render() {
    const locationState = { backTo: { pathname: { pathname: '/animals', state: { tableSearchString: this.state.searchString } }, description: 'All Patients' } };
    const COLUMNS = [
      {
        title: 'Name',
        remoteField: 'name',
        value: rowData => rowData.getName(),
        search: 'column'
      },
      {
        title: 'Species',
        remoteField: 'animalSpeciesId',
        value: rowData => rowData.getAnimalSpecies(),
        render: rowData => rowData.getAnimalSpecies()?.getName(),
        search: 'select',
        selectOptions: this.state.speciesOptions.map(value => ({ label: value?.getName(), value: value?.getId() })),
      },
      {
        title: 'Owner First Name',
        remoteField: 'ownerFirstName',
        value: rowData => rowData.getOwnerFirstName(),
        search: 'column'
      },
      {
        title: 'Owner Last Name',
        remoteField: 'ownerLastName',
        value: rowData => rowData.getOwnerLastName(),
        search: 'column'
      },
      {
        title: 'Owner Email',
        remoteField: 'ownerEmail',
        value: rowData => rowData.getOwnerEmail(),
        search: 'column'
      },
      {
        title: 'Actions',
        value: () => '',
        render: (rowData) => (
          <Link to={{ pathname: '/animals/' + rowData.getId(), state: locationState }}><VisibilityIcon /></Link>
        ),
        omitFromExport: true
      }
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Patients</Typography>

        <MaterialTable
          title='Patients'
          headerContent={
            <Button component={Link} to={{ pathname: '/animals/create', state: locationState }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listAnimals({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          options={{
            hideExport: cookies.isInstrumentSet() ? true : false,
            flipHorizontalScroll: true,
            pageSize: 20,
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />

      </div>
    );
  }
}

Animals.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Animals);
