import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, CircularProgress, Dialog, DialogTitle, Typography, IconButton } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { InventoryWidget } from './';
import { listPracticeInventoryTypes } from '../api';
import { setError } from '../alerts';
import CloseIcon from '@mui/icons-material/Close';

export default class InventorySmallWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInventoryVisible: false,
      isLoading: true,
      statusColor: 'grey'
    };
  }

  componentDidMount() {
    this.refreshInventory();
  }

  refreshInventory = () => {
    this.setState({ isLoading: true });
    listPracticeInventoryTypes().then(practiceInventoryTypes => {
      const STATUS_COLORS = ['green', 'gold', 'red'];
      let statusColorIndex = 0;
      practiceInventoryTypes.forEach(practiceInventoryType => {
        let colorIndex = 0;
        let remainingInventory = practiceInventoryType.getCount() - practiceInventoryType.getOrderTriggerNumber();
        if (remainingInventory < 0) colorIndex = 2;
        else if (practiceInventoryType.getInventoryType()?.getIsTestKit() ? (remainingInventory <= 50) : (remainingInventory <= 0)) colorIndex = 1;

        if (statusColorIndex < colorIndex) statusColorIndex = colorIndex;
      });
      this.setState({ isLoading: false, statusColor: STATUS_COLORS[statusColorIndex] });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve inventory. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  showInventory = () => {
    this.setState({ isInventoryVisible: true });
  }

  closeInventory = () => {
    this.setState({ isInventoryVisible: false });
    this.refreshInventory();
  }

  render() {
    return (
      <>
        <Card elevation={3} style={{ padding: '1em', backgroundColor: this.state.isLoading ? 'grey' : (this.state.statusColor), ...this.props.style }}>
          <CardHeader title='Inventory' style={{ color: 'white', textAlign: 'center' }} />
          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {this.state.isLoading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              <>
                <Typography variant='body1' style={{ color: 'white', textAlign: 'center', marginBottom: '1em' }}>{this.state.statusColor === 'green' ? 'Good to go!' : 'Needs Attention!'}</Typography>
                <Button secondary onClick={this.showInventory} style={{ backgroundColor: 'white', color: '#3d3d3d' }}>See Inventory</Button>
              </>
            )}

          </CardContent>
        </Card>

        <Dialog onClose={this.closeInventory} open={this.state.isInventoryVisible} fullWidth>
          <DialogTitle style={{ textAlign: 'center' }}>
            Consigned Inventory
            <IconButton
              aria-label="close"
              onClick={this.closeInventory}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <InventoryWidget />
        </Dialog>
      </>
    );
  }
}

InventorySmallWidget.propTypes = {
  style: PropTypes.object
};
