import React, { useState, useEffect } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 20) {
        setIsVisible(true);
      }
      else {
        setIsVisible(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const buttonStyle = {
    display: isVisible ? 'flex' : 'none',
    position: 'fixed',
    width: '25px',
    height: '25px',
    bottom: '0px',
    right: '0px',
    zIndex: 1001,
    border: 'none',
    outline: 'none',
    backgroundColor: '#888',
    color: 'white',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center'
  };

  return (
    <>
      <button
        onClick={scrollToTop}
        title="Go to top"
        style={buttonStyle}
      >
        <KeyboardDoubleArrowUpIcon />
      </button>
    </>
  );
}

export default ScrollToTopButton;