import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';

// Components
import { Box, CircularProgress, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';
import NewTestForm from '../../../components/forms/NewTestForm';
import { InventorySmallWidget, CartridgeSmallWidget } from '../../../components';
import { listCartridgeInventoryTypes, listInstruments, getSensorData, getPracticePimsStatus, getPractice } from '../../../api';
import { setError, removeAlertFromMessage, clearErrors } from '../../../alerts';
import cookies from '../../../cookies';
import { DARK_GREEN, InventoryType } from '@parasightsysteminc/companion-react';
import { Refresh as RefreshIcon } from '@mui/icons-material';

class NewTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToHome: false,
      redirectToTestId: null,
      /** @type {import('@parasightsysteminc/companion-react').InventoryType | null} */
      cartridgeInventoryTypes: null,
      isLoadingInstruments: false,
      isLoadingCartridgeInfo: false,
      isLoadingInstrumentRefresh: false,
      /** @type {import('@parasightsysteminc/companion-react').Instrument | null} */
      instrument: null,
      /** @type {import('@parasightsysteminc/companion-react').Instrument[]} */
      instruments: [],
      isWindowInFocus: true,
      isLoadingTestForm: false,
      /** @type {unknown} */
      refreshError: null,
      /** @type {import('../../../../../companion-portal-server/routes/practice/pims').PimsIntegrationStatus | null} */
      pimsIntegrationStatus: null,
      isEllie: null,
    };
  }

  componentDidMount() {
    // Create event listeners
    window.addEventListener('focus', () => this.setState({ isWindowInFocus: true }));
    window.addEventListener('blur', () => this.setState({ isWindowInFocus: false }));

    // If not in debug mode, instrument cookie must be set to access this page
    if (!cookies.isInstrumentSet() && !cookies.getInDebugMode()) {
      setError('Error: You must be on an instrument to start a test.');
      this.setState({ redirectToHome: true });
    }

    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      this.reloadInstruments(true);
    }

    this.retrievePimsStatus();

    getPractice().then(practice => {
      this.setState({ isEllie: practice.getIsEllie() });
    }).catch(error => console.log(error));

    /*
     * In dev mode, React.StrictMode renders everything twice, which is a huge problem when
     * we're talking about a connection to the instrument. To accommodate that without having
     * to get rid of React.StrictMode altogether (it is not granular), we check in development
     * mode to see if this is the second render before connecting to the websockets
    */
    this.hasMountedAlready = true;
  }

  retrievePimsStatus = async () => {
    // Retrieve the practice id from the url
    try {
      const pimsIntegrationStatus = await getPracticePimsStatus();
      this.setState({ pimsIntegrationStatus });
    }
    catch (error) {
      setError(error ?? 'Error: Unable to retrieve PIMS info.');
      this.setState({ pimsIntegrationStatus: null });
    }
  }

  componentWillUnmount() {
    if (this.refreshInstrumentsTimer != null) clearTimeout(this.refreshInstrumentsTimer);
    if (!this.state.redirectToHome) clearErrors();
  }

  determineCartridgeStatuses = () => {
    // Skip connection if the instrument is busy or if the cartridge statuses have already been retrieved
    if (this.state.instrument?.getIsBusy() || (!this.state.instrument?.getIsBusy() && this.state.cartridgeInventoryTypes != null)) return;

    this.setState({ isLoadingCartridgeInfo: true, cartridgeInventoryTypes: null });
    listCartridgeInventoryTypes().then(cartridgeInventoryTypes => {
      getSensorData(this.state.instrument?.getSerialNumber()).then((sensorData) => {
        InventoryType.updateStatusesFromSensorData(cartridgeInventoryTypes, sensorData, { ignoreCartridgeSensors: this.state.instrument?.getIgnoreCartridgeSensors() ?? undefined, ignoreCartridgeReadySensors: this.state.instrument?.getIgnoreCartridgeReadySensors() ?? undefined });
        this.setState({ cartridgeInventoryTypes, isLoadingCartridgeInfo: false });
        removeAlertFromMessage(this.state.refreshError);
      }).catch((error) => {
        let errorMessage = error ?? 'Error: Unable to retrieve sensor data';
        setError(errorMessage);
        this.setState({ isLoadingCartridgeInfo: false, refreshError: errorMessage });
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve cartridge inventory types');
      this.setState({ isLoadingCartridgeInfo: false });
    });
  }

  onChangeInstrument = (e) => {
    this.setState({ instrument: this.state.instruments?.find(i => i.getSerialNumber() === e.target.value), cartridgeInventoryTypes: null }, () => {
      if (this.state.instrument != null) this.determineCartridgeStatuses();
    });
  }

  reloadInstruments = (isRefresh = false) => {
    // Don't allow parallel refresh loops
    if (isRefresh && this.state.isLoadingInstrumentRefresh) return;

    if (isRefresh) {
      if (this.refreshInstrumentsTimer != null) clearTimeout(this.refreshInstrumentsTimer);

      // If the browser window is in the background, skip the update until the browser is in focus again
      // If the instrument is busy getting sensor values, skip the update until it is done
      // If the new test page is loading, skip the update until it is done
      if (!this.state.isWindowInFocus || this.state.isLoadingCartridgeInfo || this.state.isLoadingTestForm) {
        this.refreshInstrumentsTimer = setTimeout(() => this.reloadInstruments(true), 1000);
        return;
      }

      // Start the instrument refresh
      this.setState({ isLoadingInstrumentRefresh: true });
    }
    else {
      this.setState({ isLoadingInstruments: true });
    }

    // Retrieve user's list of instruments
    listInstruments().then(({ results: instruments }) => {
      if (!cookies.getInDebugMode()) {
        let instrument = instruments.find(instrument => instrument.serialNumber === cookies.getInstrumentSerialNumber());

        // If not in debug mode, instrument cookie serial number must match an active instrument
        if (instrument == null) {
          setError('Your instrument is not set up. Please set up your instrument to begin a test.');
          this.setState({ redirectToSetupInstrument: true });
          return;
        }

        if (instrument.getIsBusy()) {
          this.setState({ cartridgeInventoryTypes: null });
        }

        // If instrument is good, display it
        this.setState({ instrument, [isRefresh ? 'isLoadingInstrumentRefresh' : 'isLoadingInstruments']: false }, this.determineCartridgeStatuses);
      }

      // In debug mode, display all instruments for selection
      else {
        this.setState({ instruments, [isRefresh ? 'isLoadingInstrumentRefresh' : 'isLoadingInstruments']: false });

        // If there are no instruments, redirect to setup instrument page
        if (instruments.length === 0) {
          setError('You have no instruments set up. Please set up an instrument to begin a test.');
          this.setState({ redirectToSetupInstrument: true });
          return;
        }

        // Check if selected instrument is now busy
        let instrument = this.state.instrument;
        if (instrument != null && instrument !== '' && instruments.find(i => i.getSerialNumber() === instrument.getSerialNumber()).getIsBusy()) {
          setError('Error: Instrument is now busy.');
          this.setState({ instrument: null, cartridgeInventoryTypes: null });
        }
      }

      if (isRefresh) this.refreshInstrumentsTimer = setTimeout(() => this.reloadInstruments(true), 1000);
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve instruments');
      this.setState({ [isRefresh ? 'isLoadingInstrumentRefresh' : 'isLoadingInstruments']: false });
    });
  }

  render() {
    if (this.state.redirectToHome) return <Redirect to='/' />;
    else if (this.state.redirectToSetupInstrument) return <Redirect to={{ pathname: '/instruments/setup', state: this.props.location?.state }} />;
    return this.state.redirectToTestId != null ? <Redirect to={'/tests/' + this.state.redirectToTestId} /> : (
      <Box style={{ paddingTop: '20px', paddingLeft: '2%', paddingRight: '2%' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Start New Test</Typography>

        <div>
          <Grid container columnSpacing={2} rowSpacing={5} style={{ direction: 'flex', flexDirection: 'row-reverse' }}>
            {/* Test Form Grid Item */}
            <Grid item lg={10} xs={12}>
              <Typography variant='h2'>Instrument</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>

                {/* DEBUG MODE INSTRUMENT SELECTION */}
                {cookies.getInDebugMode() &&
                <TextField
                  select
                  required
                  name='instrument'
                  label='Instrument'
                  fullWidth
                  value={this.state.instrument != null && this.state.instrument !== '' ? this.state.instrument.getSerialNumber() : ''}
                  onChange={this.onChangeInstrument}
                  variant='filled'
                  style={{ width: '100%' }}
                  disabled={this.state.isLoadingCartridgeInfo}>
                  {this.state.instruments?.map((option, index) => {
                    let friendlyName = option.getFriendlyName() != null && option.getFriendlyName() !== '' ? ' (' + option.getFriendlyName() + '): ' : ': ';
                    let optionName = option.getSerialNumber() + friendlyName + (option.isBusy ? 'Busy' : 'Available');
                    return (
                      <MenuItem key={index} value={option.getSerialNumber() ?? ''} disabled={option.getIsBusy() ?? false}>{optionName}</MenuItem>
                    );
                  })}
                </TextField>}

                {/* NON-DEBUG MODE INSTRUMENT DISPLAY */}
                {!cookies.getInDebugMode() && this.state.instrument != null && this.state.instrument !== '' &&
                <Typography>{this.state.instrument.getSerialNumber()}{this.state.instrument.getFriendlyName() != null ? ` (${this.state.instrument.getFriendlyName()}): ` : ': '}{this.state.instrument.getIsBusy() ? 'Busy' : 'Available'}</Typography>}

                <IconButton style={{ backgroundColor: DARK_GREEN, color: 'white', marginLeft: '10px', opacity: this.state.isLoadingInstruments || this.state.isLoadingCartridgeInfo ? 0.5 : 1 }} onClick={() => this.reloadInstruments(false)} disabled={this.state.isLoadingInstruments || this.state.isLoadingCartridgeInfo}><RefreshIcon /></IconButton>
              </div>

              {/* Connecting to Instrument Loading */}
              {this.state.isLoadingCartridgeInfo &&
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
                <Typography variant='h3' style={{ marginLeft: '15px' }}>Connecting to Instrument...</Typography>
              </div>}

              {/* New Test Form */}
              {this.state.cartridgeInventoryTypes != null && this.state.isEllie != null &&
              <NewTestForm
                supportsPimsLookup={this.state.pimsIntegrationStatus?.supportsPatientLookup ?? false}
                onSuccess={(test) => this.setState({ redirectToTestId: test?.getId() })}
                cartridgeInventoryTypes={this.state.cartridgeInventoryTypes ?? []}
                instrument={this.state.instrument ?? this.state.instruments[0]}
                onLoading={(isLoading) => this.setState({ isLoadingTestForm: isLoading })}
                isEllie={this.state.isEllie}
              />}
            </Grid>

            {/* Inventory Widget Grid Item */}
            <Grid item lg={2} xs={12}>
              <>
                <CartridgeSmallWidget cartridgeInventoryTypes={this.state.cartridgeInventoryTypes} isLoading={this.state.isLoadingCartridgeInfo} instrument={this.state.instrument} />
                <InventorySmallWidget style={{ marginTop: '20px' }} />
              </>
            </Grid>
          </Grid>
        </div>
      </Box>
    );
  }
}

NewTest.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(NewTest);
