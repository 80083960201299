import React, { Component } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ParasightWebsite from '../../assets/images/qr/parasight-website.png';
import AioRunningTest from '../../assets/images/qr/aio-running-test.png';
import ReplaceEmptyCartridge from '../../assets/images/qr/replace-empty-cartridge.png';
import InstallCartridges from '../../assets/images/qr/install-cartridges.png';
import PastureSamplePrep from '../../assets/images/qr/pasture-sample-prep.png';
import HookRoundWhipTapeworm from '../../assets/images/qr/hrw-tapeworm.png';
import Coccidia from '../../assets/images/qr/coccidia.png';
import CloggedChamber from '../../assets/images/qr/clogged-chamber.png';
import FattySample from '../../assets/images/qr/fatty-sample.png';

const QR_CODES = [
  {
    src: Coccidia,
    title: 'Coccidia (New)'
  },
  {
    src: HookRoundWhipTapeworm,
    title: 'Hookworm, Roundworm, Whipworm, and Tapeworm'
  },
  {
    src: AioRunningTest,
    title: 'All-In-One Full Tutorial Running Test'
  },
  {
    src: ReplaceEmptyCartridge,
    title: 'Replacing Empty Cartridges'
  },
  {
    src: InstallCartridges,
    title: 'Installing Cartridges at Setup'
  },
  {
    src: PastureSamplePrep,
    title: 'All-In-One Sample Prep - Pasture Animals'
  },
  {
    src: ParasightWebsite,
    title: 'Parasight System Website'
  },
  {
    src: CloggedChamber,
    title: 'What Can Cause A Clogged Specimen Chamber'
  },
  {
    src: FattySample,
    title: 'How to Process a Fatty Fecal Sample'
  }
];

class Tutorials extends Component {
  render() {
    return (
      <Container style={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} center>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Tutorials</Typography>

        <Grid container spacing={3} justifyContent={'center'}>
          {QR_CODES.map((qr, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <img src={qr.src} alt={qr.title} style={{ width: '200px', objectFit: 'contain' }} />
              <Typography variant='h6' style={{ textAlign: 'center', marginTop: '1em' }}>{qr.title}</Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
}

Tutorials.propTypes = {
};

export default Tutorials;
