import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Typography,  TextField, InputAdornment, IconButton } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import PropTypes from 'prop-types';
import { Logo } from '@parasightsysteminc/companion-react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

//APIs
import { forgotPasswordConfirm } from '../../../../../api';

//ALERTS
import { setError, setSuccess } from '../../../../../alerts';

class ForgotPasswordConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      isLoading: false,
      redirectToLogin: false,
      showPassword: false
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    //check if passwords match
    if (this.state.password !== this.state.confirmPassword) {
      setError('Passwords do not match. Please try again.');
      return;
    }

    this.setState({ isLoading: true });

    //send token and password to API
    forgotPasswordConfirm({ token: this.props.match?.params?.token, password: this.state.password }).then(() => {
      setSuccess('Successfully reset password!');
      this.setState({ isLoading: false, redirectToLogin: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to reset password. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return this.state.redirectToLogin ? <Redirect to='/auth/login' /> : (
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Logo} style={{ width: '12em', marginTop: '2.5em' }}></img>
        </div>

        <form onSubmit={this.onSubmit}>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Forgot Password Confirm</Typography>

          {/* Password */}
          <TextField
            required
            name='password'
            label='New Password'
            type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.onChange}
            disabled={this.state.isLoading}
            variant='filled'
            helperText='Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Confirm Password */}
          <TextField
            required
            name='confirmPassword'
            label='Confirm New Password'
            type={this.state.showPassword ? 'text' : 'password'}
            onChange={this.onChange}
            disabled={this.state.isLoading}
            variant='filled'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Submit */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type='submit'
              style={{ width: '48%', marginTop: '1em' }} isLoading={this.state.isLoading}>
              Submit
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

ForgotPasswordConfirm.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(ForgotPasswordConfirm);
