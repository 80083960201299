import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Select,
  FormControl,
  FormLabel,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { checkClients, getClientAnimals } from '../../api';
import PropTypes from 'prop-types';
import { setError } from '../../alerts';
import AlertList from '../AlertList';

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {(selectedAnimal: import('@parasightsysteminc/companion-react').Animal) => void} props.onSuccess
 * @param {() => void} props.onCancel
 */
export function SearchClientAndAnimalDialog({ open, onSuccess, onCancel }) {
  const [clients, setClients] = useState(
    /** @type {Awaited<ReturnType<typeof checkClients>> | null} */ (null),
  );
  const [selectedClientId, setSelectedClientId] = useState(
    /** @type {string | null} */ (null),
  );

  const [loading, setLoading] = useState(false);
  const [selectedClientAnimals, setSelectedClientAnimals] = useState(
    /** @type {Awaited<ReturnType<typeof getClientAnimals>> | null} */ (null),
  );

  const searchClients = useCallback(
    /**
     * @param {FormData} formData
     */
    async (formData) => {
      setLoading(true);
      try {
        /** @type {Parameters<typeof checkClients>[0]} */
        const params = {
          firstName: formData.get('firstName') ?? undefined,
          lastName: formData.get('lastName') ?? undefined,
          email: formData.get('email') ?? undefined,
          phone: formData.get('phone') ?? undefined,
        };
        const clients = await checkClients(params);
        setClients(clients);
        if (clients.clients.length === 1) {
          setSelectedClientId(clients.clients[0].id);
        }
      }
      finally {
        setLoading(false);
      }
    },
    [],
  );

  const loadClientAnimals = useCallback(
    /**
     * @param {string} clientId
     */
    async (clientId) => {
      setLoading(true);
      try {
        setSelectedClientAnimals(await getClientAnimals({ clientId }));
      }
      finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (selectedClientId) {
      loadClientAnimals(selectedClientId).catch((err) => setError(String(err)));
    }
  }, [selectedClientId, loadClientAnimals]);

  let content = null;

  if (!selectedClientId) {
    content = (
      <>
        <form
          style={{ width: '100%' }}
          onSubmit={(e) => {
            e.preventDefault();
            setClients(null);
            searchClients(new FormData(e.target)).catch((err) => setError(String(err)));
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            {'Name:'}
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                gap: '1rem',
                width: '100%',
              }}
            >
              <TextField
                name="firstName"
                label="First name"
                style={{ width: '50%' }}
              />
              <TextField
                name="lastName"
                label="Last name"
                style={{ width: '50%' }}
              />
            </div>
            {'Contact:'}
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                gap: '1rem',
                width: '100%',
              }}
            >
              <TextField
                name="email"
                label="Email"
                type="email"
                style={{ width: '50%' }}
              />
              <TextField
                name="phone"
                label="Phone"
                type="tel"
                style={{ width: '50%' }}
              />
            </div>
            <Button
              type="submit"
              isLoading={loading}
            >
              Search
            </Button>
          </Box>
        </form>
        <p>
          Note that you must search by complete terms. For example, if you are
          looking for an owner named <i>John</i> with an email of <i>john@example.com</i>,
          you cannot just search for <i>Jo</i> and <i>john@example</i>. You instead must
          search for <i>John</i> and <i>john@example.com</i>.
        </p>
        {loading && <CircularProgress />}
        {clients && (clients.clients.length === 0 ? (
          <p>No animals found for this owner</p>
        ) : (
          <FormControl fullWidth>
            <FormLabel>Found clients</FormLabel>
            <Select
              onChange={(e) => {
                setSelectedClientId(e.target.value);
              }}
            >
              {clients.clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.firstName} {client.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
        )}
        {clients?.truncated && (
          <p>Some results are excluded, please refine your search</p>
        )}
      </>
    );
  }
  else {
    content = (
      <>
        {loading && <CircularProgress />}
        <FormControl disabled={loading} fullWidth>
          <FormLabel>Select a Patient</FormLabel>
          {!selectedClientAnimals?.animals || selectedClientAnimals.animals.length === 0 ? (
            <p>No animals found for this owner</p>
          ) : (
            <Select
              onChange={(e) => {
                const selectedAnimal = selectedClientAnimals?.animals.find(
                  (animal) => animal.id === e.target.value,
                );
                if (selectedAnimal) {
                  onSuccess(selectedAnimal);
                  setSelectedClientId(null);
                  setClients(null);
                  setSelectedClientAnimals(null);
                  setLoading(false);
                }
              }}
              disabled={!selectedClientAnimals}
            >
              {selectedClientAnimals?.animals.map((animal) => (
                <MenuItem key={animal.id ?? ''} value={animal.id ?? ''}>
                  {animal.name}{' '}
                </MenuItem>
              ))}
            </Select>
          )}
          {clients?.truncated && (
            <p>Not all of this owner&apos;s animals are shown</p>
          )}
        </FormControl>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onCancel();
        setSelectedClientId(null);
        setClients(null);
        setSelectedClientAnimals(null);
        setLoading(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Search PIMS by Owner</DialogTitle>
      <AlertList />
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {content}
      </DialogContent>
    </Dialog>
  );
}

SearchClientAndAnimalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
