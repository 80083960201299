import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, Dialog, DialogTitle, Skeleton, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { InventoryType, INVENTORY_TYPE_STATUSES } from '@parasightsysteminc/companion-react';

export default class CartridgeSmallWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false
    };
  }

  showDialog = () => {
    this.setState({ isDialogOpen: true });
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  }

  render() {
    // From the list of cartridge inventory types, get the status of the inventory types and associated color
    let inventoryTypeStatus = InventoryType.getListStatus(this.props.cartridgeInventoryTypes);
    let statusColor = 'grey';
    const hasExpiredCartridge = this.props.instrument?.hasExpiredCartridge();
    if (inventoryTypeStatus === INVENTORY_TYPE_STATUSES.GOOD && !hasExpiredCartridge) statusColor = 'green';
    else if (inventoryTypeStatus === INVENTORY_TYPE_STATUSES.LOW && !hasExpiredCartridge) statusColor = 'gold';
    else if (inventoryTypeStatus !== INVENTORY_TYPE_STATUSES.UNKNOWN) statusColor = 'red';

    return (
      <>
        <Card elevation={3} style={{ padding: '1em', backgroundColor: this.state.isLoading ? 'grey' : (statusColor), ...this.props.style }}>
          <CardHeader title='Cartridges' style={{ color: 'white', textAlign: 'center' }} />
          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {this.props.isLoading ? (
              <>
                <Skeleton variant="text" width='100%' />
                <Skeleton variant="rounded" width={'100%'} height={60} />
              </>
            ) : (
              <>
                <Typography variant='body1' style={{ color: 'white', textAlign: 'center', marginBottom: '1em' }}>{inventoryTypeStatus === INVENTORY_TYPE_STATUSES.GOOD && !hasExpiredCartridge ? 'Good to go!' : inventoryTypeStatus === INVENTORY_TYPE_STATUSES.UNKNOWN && !hasExpiredCartridge ? 'Unknown' : 'Needs Attention!'}</Typography>
                <Button secondary component={Link} to={`/instruments/${this.props.instrument?.getSerialNumber()}/status`} style={{ backgroundColor: 'white', color: '#3d3d3d', opacity: inventoryTypeStatus === INVENTORY_TYPE_STATUSES.UNKNOWN ? 0.5 : 1 }} disabled={inventoryTypeStatus === INVENTORY_TYPE_STATUSES.UNKNOWN}>View Status</Button>
              </>
            )}

          </CardContent>
        </Card>

        <Dialog onClose={this.closeDialog} open={this.state.isDialogOpen}>
          <DialogTitle style={{ textAlign: 'center' }}>Cartridge Status</DialogTitle>
        </Dialog>
      </>
    );
  }
}

CartridgeSmallWidget.propTypes = {
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  cartridgeInventoryTypes: PropTypes.arrayOf(PropTypes.object),
  instrument: PropTypes.object,
};
