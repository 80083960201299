import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Navigation from './navigation';
import ScrollToTop from './ScrollToTop';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import ScrollToTopButton from './navigation/ScrollToTopButton';
import ScrollToBottomButton from './navigation/ScrollToBottomButton';
import { getNewestPortalVersion, getUnderConstruction, getInfoBanner } from './api';
import semver from 'semver';
import { setBanner, setUnderConstruction } from './alerts';

// Components
import { AlertList, ConfirmationModal } from './components';
import AppBar from './navigation/AppBar';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

function App() {

  // Function to fetch under construction and info banner data
  const fetchConstructionAndBannerData = () => {
    getUnderConstruction().then(underConstruction => {
      setUnderConstruction(underConstruction === true ? 'The platform is under construction. Tests cannot be run until further notice.' : null);
    }).catch(error => {
      console.log(error);
    });

    getInfoBanner().then(infoBanner => {
      setBanner(infoBanner != null ? infoBanner : null);
    }).catch(error => {
      console.log(error);
    });
  };

  // If new portal version is available, force reload
  useEffect(() => {
    getNewestPortalVersion().then(portalVersion => {
      if (semver.gt(portalVersion.getSemanticVersion(), process.env.REACT_APP_VERSION)) {
        window.location.reload();
      }
    }).catch(error => {
      console.log(error);
    });

    // Check under construction and info banner every 5 minutes
    fetchConstructionAndBannerData();
    const interval = setInterval(fetchConstructionAndBannerData, 300000);

    // Clear interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <ScrollToTopButton />
        <ScrollToBottomButton />
        <ThemeProvider theme={theme}>
          <div style={{ position: 'relative', minHeight: '100vh' }}>
            <AlertList />
            <ConfirmationModal />
            <AppBar />
            <Navigation />
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
