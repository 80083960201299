import React, { Component } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookies from '../../../cookies';

// API
import { getInstrument, deactivateInstrument, freeInstrument, updateInstrument } from '../../../api';

// Components
import { CircularProgress, Container, Typography, TextField } from '@mui/material';
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';

// Alerts
import { setError, setSuccess } from '../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

class Instrument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      instrument: null,
      inEditMode: false,
      redirectToInstruments: false,
      isLoadingFreeing: false,
      friendlyName: ''
    };
  }

  componentDidMount() {
    this.retrieveInstrument();
  }

  retrieveInstrument = () => {
    // Retrieve the instrument id from the url
    const instrumentSerialNumber = this.props.match?.params?.serialNumber;

    this.setState({ isLoading: true });
    getInstrument(instrumentSerialNumber).then(instrument => {
      this.setState({ isLoading: false, instrument });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve instrument info. Please try again.');
      this.setState({ isLoading: false, redirectToInstruments: true });
    });
  }

  openEditMode = () => {
    this.setState({ inEditMode: true, friendlyName: this.state.instrument.getFriendlyName() ?? '' });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false, friendlyName: '' });
  }

  saveInstrumentChanges = (e) => {
    e.preventDefault();

    this.setState({ isLoadingEdit: true });
    updateInstrument(this.state.instrument.getSerialNumber(), { friendlyName: this.state.friendlyName }).then(instrument => {
      this.setState({ instrument, isLoadingEdit: false, inEditMode: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to update instrument.');
      this.setState({ isLoadingEdit: false });
    });
  }

  onSuccess = () => {
    this.retrieveInstrument();
    this.closeEditMode();
  }

  deactivateConfirm = () => {
    confirm({
      title: `Are you sure you want to deactivate the instrument with serial number ${this.state.instrument.getSerialNumber()}?`,
      body: 'You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        deactivateInstrument(this.state.instrument?.getSerialNumber()).then((instrument) => {
          setSuccess('Successfully deactivated instrument!');
          this.setState({ instrument, redirectToInstruments: true });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to deactivate instrument.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  freeInstrument = () => {
    this.setState({ isLoadingFreeing: true });
    freeInstrument(this.state.instrument.getSerialNumber()).then(instrument => {
      this.setState({ instrument, isLoadingFreeing: false });
      setSuccess('Successfully freed instrument');
    }).catch(error => {
      setError(error ?? 'Error: Unable to free instrument.');
      this.setState({ isLoadingFreeing: false });
    });
  }

  render() {
    const locationState = { backTo: { pathname: this.props.location, description: `Instrument${this.state.instrument?.getSerialNumber() != null ? ` ${this.state.instrument.getSerialNumber()}` : ''}` } };
    return this.state.redirectToInstruments ? (
      <Redirect to={this.props.location?.state?.backTo ?? '/instruments'} />
    ) : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        {this.state.instrument != null &&
        <>
          <Typography
            variant='h1'
            style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
            Instrument{this.state.instrument != null ? ': ' + this.state.instrument.getSerialNumber() : ''}
          </Typography>

          {this.state.isLoading ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            this.state.inEditMode ? (
              <form onSubmit={this.saveInstrumentChanges}>
                <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Edit Instrument</Typography>

                <TextField required style={{ width: '300px', marginTop: '1em' }} label="Display Name" onChange={(e) => this.setState({ friendlyName: e.target.value })} value={this.state.friendlyName} variant="outlined"  />

                <div style={{ marginTop: '50px' }}>
                  <Button secondary onClick={this.closeEditMode} disabled={this.state.isLoadingEdit}>Cancel</Button>
                  <Button type='submit' style={{ marginLeft: '1em' }} isLoading={this.state.isLoadingEdit}>Save Changes</Button>
                </div>
              </form>
            ) : (
              <>
                <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Instrument Information</Typography>
                <div>
                  <LineItem
                    value={this.state.instrument.getSerialNumber()}
                    description='Serial Number'
                  />

                  <LineItem
                    value={this.state.instrument.getFriendlyName() || '(None)'}
                    description='Display Name'
                  />

                  <LineItem
                    value={this.state.instrument.getIsBusy() ? 'Busy' : 'Free'}
                    description='Is Busy'
                  />

                  <LineItem
                    value={this.state.instrument.getIsSuspended() ? 'Suspended' : 'Active'}
                    description='Is Suspended'
                  />
                </div>

                <div style={{ marginTop: '50px' }}>
                  <Button secondary onClick={this.openEditMode}>Edit Instrument</Button>
                </div>

                {(cookies.getInDebugMode() || cookies.getInstrumentSerialNumber() === this.state.instrument.getSerialNumber()) &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button secondary component={Link} to={{ pathname: `/instruments/${this.state.instrument.getSerialNumber()}/status`, state: locationState }}>View Cartridge Status</Button>
                </div>}

                {this.state.instrument.getIsBusy() &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button secondary onClick={this.freeInstrument} isLoading={this.state.isLoadingFreeing}>Set Instrument Free</Button>
                </div>}

                {cookies.getInDebugMode() &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={this.deactivateConfirm}>Deactivate Instrument</Button>
                </div>}

                {cookies.getInDebugMode() && cookies.getInstrumentSerialNumber() !== this.state.instrument.getSerialNumber() &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={() => {
                    cookies.setInstrumentSerialNumber(this.state.instrument.getSerialNumber());
                    setSuccess('Successfully set instrument active!');
                    window?.location?.reload(false);
                  }}>Set Active Instrument (Debug Only)</Button>
                </div>}

                {cookies.getInDebugMode() && cookies.getInstrumentSerialNumber() === this.state.instrument.getSerialNumber() &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={() => {
                    cookies.clearInstrument();
                    setSuccess('Successfully removed active instrument!');
                    window?.location?.reload(false);
                  }}>Remove Active Instrument (Debug Only)</Button>
                </div>}
              </>
            )
          )}
        </>}
      </Container>
    );
  }
}

Instrument.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Instrument);
