import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { setError } from '../../alerts';
import cookies from '../../cookies';

// Components
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listAnimalSpecies, listTreatments } from '../../api';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

class Treatments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      animalSpecies: []
    };
  }

  componentDidMount() {
    listAnimalSpecies().then((res) => {
      this.setState({
        animalSpecies: res,
        isLoading: false
      });
    }).catch((error) => {
      setError(error);
    });
  }

  render() {
    const COLUMNS = [
      {
        title: 'Logo',
        remoteField: 'practiceId',
        render: (rowData) => rowData.getImageUrl() != null ? <img src={rowData.getImageUrl()} width={64} height={64} style={{ objectFit: 'contain' }} /> : <div />,
        search: 'none',
        omitFromExport: true
      },
      {
        title: 'Name',
        remoteField: 'name',
        value: rowData => rowData.getName(),
        render: rowData => (<Typography sx={{ fontStyle: rowData.getPracticeId() === null ? 'italic' : 'normal' }}>{rowData.getName()}</Typography>),
        search: 'column'
      },
      {
        title: 'Actions',
        value: () => '',
        render: (rowData) => (
          <Link to={{ pathname: '/treatments/' + rowData.getId(), state: { backTo: { pathname: this.props.location, description: 'All Treatments' } } }}><VisibilityIcon /></Link>
        ),
        search: 'none',
        omitFromExport: true
      }
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Treatments</Typography>

        {this.state.animalSpecies.map((animalSpecies) => (
          <div key={animalSpecies.getId()}>
            <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}>{animalSpecies.getName()} Treatments</Typography>
            <MaterialTable
              title={`${animalSpecies.getName()} Treatments`}
              headerContent={
                <Button component={Link} to={{ pathname: '/treatments/create', search: `?animalSpeciesId=${animalSpecies.getId()}`, state: { backTo: { pathname: this.props.location, description: 'All Treatments' } } }}>
                  Create New
                  <AddIcon style={{ marginLeft: '5px' }} />
                </Button>
              }
              data={{
                mode: 'remote',
                columns: COLUMNS,
                fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
                  new Promise((resolve, reject) => {
                    let options = {
                      offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                      limit: rowsPerPage,
                      where,
                      order,
                    };
                    listTreatments({ animalSpeciesId: animalSpecies.getId(), options }).then((res) => {
                      resolve(res);
                    }).catch((error) => {
                      reject(error);
                    });
                  })
              }}
              onError={(error) => {
                setError(error);
              }}
              options={{
                hideExport: cookies.isInstrumentSet() ? true : false,
                flipHorizontalScroll: true
              }}
            />
          </div>
        ))}
      </div>
    );
  }
}

Treatments.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Treatments);
