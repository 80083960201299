import store from '../redux/store';
import { clearErrors as reduxClearErrors, setError as reduxSetError, setBanner as reduxSetBanner, setUnderConstruction as reduxSetUnderConstruction, setSuccess as reduxSetSuccess, removeAlert as reduxRemoveAlert, removeAlertFromMessage as reduxRemoveAlertFromMessage } from '../redux/actions/alertActions';

export function setSuccess(...props) {
  reduxSetSuccess(store?.dispatch, ...props);
}

export function clearErrors() {
  store?.dispatch(reduxClearErrors());
}

export function setError(...props) {
  reduxSetError(store?.dispatch, ...props);
}

export function removeAlert(...props) {
  store?.dispatch(reduxRemoveAlert(...props));
}

export function removeAlertFromMessage(...props) {
  store?.dispatch(reduxRemoveAlertFromMessage(...props));
}

export function setBanner(...props) {
  store?.dispatch(reduxSetBanner(...props));
}

export function setUnderConstruction(...props) {
  store?.dispatch(reduxSetUnderConstruction(...props));
}