import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lexcelon/react-util';
import { DogPicture, CatPicture, HorsePicture, GoatPicture, CattlePicture, AvianPicture, Instrument, LIGHTEST_GREEN, Animal } from '@parasightsysteminc/companion-react';
import { Grid, ToggleButton, ToggleButtonGroup, Typography, Autocomplete, MenuItem, TextField, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import { Redirect } from 'react-router-dom';
import { VeterinarianDialog, LabTechDialog, AnimalDialog, SearchClientAndAnimalDialog } from '..';
import { Block as BlockIcon, PriorityHigh as PriorityHighIcon, QuestionMark as QuestionMarkIcon, CheckCircleOutline as CheckCircleOutlineIcon, RadioButtonUnchecked as RadioButtonUncheckedIcon, ReportProblem, AccessTimeFilled as AccessTimeFilledIcon } from '@mui/icons-material';
import { formatString } from '../../util';
import cookies from '../../cookies';
import SafetyImage from '../../assets/images/safety.png';
import { styled, darken } from '@mui/material/styles';

//APIs
import { listVeterinarians, listLabTechs, createTest, listTestTypes, getUser, listAnimalsForAutocomplete, createEllieTest } from '../../api';

import { setError, setSuccess } from '../../alerts';
import { INVENTORY_TYPE_STATUSES } from '@parasightsysteminc/companion-react';
import { closeConfirm, confirm, startConfirmLoading, stopConfirmLoading } from '../../alerts/confirm';
import { DateTime } from 'luxon';

// Constants
const ANIMAL_AGES = [
  'Less than 1 year old',
  '1 or more years old'
];
const SAMPLE_QUALITY_DESCRIPTORS = [
  {
    value: 'INSUFFICIENT',
    label: 'Insufficient',
    description: 'Cannot run test',
  },
  {
    value: 'INCONCLUSIVE',
    label: 'Inconclusive',
    description: 'Cannot be certain of results',
  },
  {
    value: 'SUFFICIENT',
    label: 'Sufficient',
    description: 'Adequate sample size',
  },
];

const StyledToggleButton = styled(ToggleButton)(({ backgroundColors = [LIGHTEST_GREEN], borderWidth = '4px' }) => {
  // Order the background colors lightest first
  backgroundColors.sort().reverse();

  let gradient = 'linear-gradient(90deg, ';
  let total = 0;
  let diff = Math.round(100 / (-1 + backgroundColors.length * 3));
  backgroundColors.forEach((color, index) => {
    gradient += `${color} ${total}%, ${color} ${total + diff * 2}%` + (index === backgroundColors.length - 1 ? '' : ', ');
    total += diff * 3;
  });
  gradient += ')';

  return ({
    background: backgroundColors.length > 1 ? gradient : undefined,
    backgroundColor: backgroundColors.length > 1 ? undefined : backgroundColors[0],
    borderLeftColor: backgroundColors.length > 1 ? darken(backgroundColors[0], 0.1) : undefined,
    borderRightColor: backgroundColors.length > 1 ? darken(backgroundColors[backgroundColors.length - 1], 0.1) : undefined,
    borderWidth,
    '&.Mui-selected': {
      background: backgroundColors.length > 1 ? gradient : undefined,
      backgroundColor: backgroundColors.length > 1 ? undefined : backgroundColors[0],
      '&:hover': {
        background: backgroundColors.length > 1 ? gradient : undefined,
        backgroundColor: backgroundColors.length > 1 ? undefined : backgroundColors[0],
      },
    },
    '&:hover': {
      background: backgroundColors.length > 1 ? gradient : undefined,
      backgroundColor: backgroundColors.length > 1 ? undefined : backgroundColors[0],
    },
  });
});

class NewTestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /** @type {import("@parasightsysteminc/companion-react").TestType[]} */
      testTypes: [],
      /** @type {import("@parasightsysteminc/companion-react").Veterinarian | null} */
      veterinarian: null,
      /** @type {import("@parasightsysteminc/companion-react").LabTech | null} */
      labTech: null,
      /** @type {import("@parasightsysteminc/companion-react").Animal | null} */
      animal: null,
      /** @type {import("@parasightsysteminc/companion-react").Veterinarian[]} */
      veterinarians: [],
      /** @type {import("@parasightsysteminc/companion-react").LabTech[]} */
      labTechs: [],
      /** @type {import("@parasightsysteminc/companion-react").Animal[] | null} */
      animals: null,
      /** @type {import("@parasightsysteminc/companion-react").InventoryType[]} */
      inventoryTypes: [],
      selectedObject: null,
      veterinarianPerformingTest: false,
      isLoading: false,
      loadListsFailed: false,
      animalAge: '',
      sampleName: '',
      comments: '',
      animalSpeciesId: 1,
      redirectToHome: false,
      patientNameText: '',
      /** @type {import("@parasightsysteminc/companion-react").User | null} */
      user: null,
      animalInputValue: '',
      checkPimsDialogOpen: false,
      selectionMode: 'TEST_TYPE', // Alternatively, TEST_TYPE_GROUP
      pageIndex: 0,
      labdaqAccessionId: '',
      sampleQualityDescriptor: null,
    };

    // Debounce the fetchOptions method
    this.debouncedFetchOptions = this.debounce(this.fetchOptions, 300);
  }

  debounce(func, delay) {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  fetchOptions = async (input) => {
    if (input.length < 2) {
      // Only fetch if input length is at least 2
      this.setState({ animals: null });
      return;
    }

    listAnimalsForAutocomplete(input).then((results) => {
      this.setState({ animals: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve animals. Please try again.');
    });
  }

  handleInputChange = (event, newInputValue) => {
    this.setState({ animalInputValue: newInputValue });
    this.debouncedFetchOptions(newInputValue);
  }

  loadLists = () => {
    getUser().then(user => {
      this.setState({ user });

      //Load Veterinarians
      listVeterinarians().then(({ results }) => {
        let selectedVeterinarian = results.length === 1 ? results[0] : null;
        results.map(veterinarian => {
          if (veterinarian.getId() === user.getLastUsedVeterinarianId()) selectedVeterinarian = veterinarian;
        });
        this.setState({ veterinarians: results, veterinarian: selectedVeterinarian });
      }).catch(error => { //Catch veterinarian error
        setError(error ?? 'Error: Unable to retrieve veterinarians. Please try again.');
        this.setState({ loadListsFailed: true });
      });

      //Load LabTechs
      listLabTechs().then(({ results }) => {
        let selectedLabTech = null;
        results.map(labTech => {
          if (labTech.getId() === user.getLastUsedLabTechId()) selectedLabTech = labTech;
        });
        this.setState({ labTechs: results, labTech: selectedLabTech, veterinarianPerformingTest: selectedLabTech == null });
      }).catch(error => { //Catch lab tech error
        setError(error ?? 'Error: Unable to retrieve lab techs. Please try again.');
        this.setState({ loadListsFailed: true });
      });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve user object.');
    });
  }

  componentDidMount() {
    this.loadLists();
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.selectedObject?.getId() == null) {
      setError('Error: Please select a test type.');
      this.setState({ pageIndex: 0 });
      return;
    }

    let testType = this.state.selectedObject;
    if (this.state.selectionMode !== 'TEST_TYPE') {
      if (this.state.isFecalLoop == null) {
        setError('Error: Please select a sample quantity.');
        return;
      }

      // Set testType to the appropriate test type
      testType = this.state.selectedObject?.getTestTypes().find(testType => testType.getDisplayType() === (this.state.isFecalLoop ? 'PRESENCE_OF' : 'EPG'));

      if (testType == null) {
        setError('Error: No matching test type for your selected sample size.');
        return;
      }
    }

    // Give a warning if in training mode
    if (this.state.user?.getPractice()?.getIsDemo() && this.state.user?.getPractice()?.getIdentifier() !== 'PTEST1') {
      confirm({
        title: 'WARNING: Test in Training Mode',
        body: 'Training mode is for demonstrative purposes ONLY. Tests performed in training mode use an abbreviated cycle with results replicated from previous tests. The results from this test will not be valid with the fecal sample you provide. To turn off training mode, go to your account settings. Are you sure you want to continue?',
        onConfirm: () => {
          this.createTest(testType.id);
        },
        danger: true
      });
    }
    else if (this.state.animalSpeciesId >= 3 /* Pasture Animal */) {
      confirm({
        title: 'Attention: Please follow the pasture preparation procedure before running a test.',
        body: 'Are you ready to continue?',
        onConfirm: () => {
          this.createTest(testType.id);
        },
        danger: true
      });
    }
    else this.createTest(testType.id);
  }

  onSubmitEllie = (e) => {
    e.preventDefault();

    if (this.state.labdaqAccessionId == null || this.state.labdaqAccessionId === '') {
      setError('Error: Please enter a Lab Daq Accession ID.');
      return;
    }
    if (this.state.sampleQualityDescriptor == null) {
      setError('Error: Please select a sample quality descriptor.');
      return;
    }

    // Start loading
    this.setState({ isLoading: true });
    createEllieTest({
      labdaqAccessionId: this.state.labdaqAccessionId,
      sampleQualityDescriptor: this.state.sampleQualityDescriptor,
      instrumentSerialNumber: this.props.instrument instanceof Instrument ? this.props.instrument.getSerialNumber() : undefined
    }).then(test => {
      this.setState({ isLoading: false });
      if (test != null) {
        setSuccess('Successfully created test!');
        this.props.onSuccess(test);
      }
      else {
        setSuccess('Successfully saved test!');

        // Clear form
        this.setState({ labdaqAccessionId: '', sampleQualityDescriptor: null });
      }
    }).catch(error => {
      setError(error ?? 'Error: Unable to create test. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  createTest = (testTypeId) => {
    // Start loading
    this.setState({ isLoading: true });
    this.props.onLoading(true);
    startConfirmLoading();

    if (this.state.veterinarianPerformingTest) {
      this.setState({ labTech: null });
    }

    createTest({
      veterinarianId: this.state.veterinarian?.getId(),
      labTechId: this.state.labTech?.getId() ?? null,
      animalId: this.state.animal.getId(),
      testTypeId,
      // This check is probably not needed, but it's here just in case
      instrumentSerialNumber: this.props.instrument instanceof Instrument ? this.props.instrument.getSerialNumber() : undefined,
      animalAge: this.state.animalAge,
      sampleName: this.state.sampleName !== '' ? this.state.sampleName : null,
      comments: this.state.comments !== '' ? this.state.comments : null
    }).then((test) => {
      setSuccess('Successfully created test!');
      this.setState({ isLoading: false });
      this.props.onLoading(false);
      stopConfirmLoading();
      closeConfirm();
      this.props.onSuccess(test);
    }).catch((error) => {
      setError(error ?? 'Error: Unable to create test. Please try again.');
      this.setState({ isLoading: false });
      stopConfirmLoading();
      closeConfirm();
      this.props.onLoading(false);
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onVeterinarianChange = (_, newVeterinarian) => {
    this.setState({ veterinarian: newVeterinarian });
  }

  onLabTechChange = (_, newLabTech) => {
    this.setState({ labTech: newLabTech });
  }

  onAnimalChange = (newAnimal) => {
    this.updateTestTypes(newAnimal);
  }

  // This is only used to autofill the name field if user opts to Create New Patient
  // For actual patient selection, see onAnimalChange above
  onPatientNameTextChange = (e) => {
    this.setState({ patientNameText: e.target.value });
  }

  onTestTypeChange = (_, newTestType) => {
    this.setState({ testType: newTestType });
  }

  onNewVeterinarianSuccess = (newVeterinarian) => {
    this.state.veterinarians.push(newVeterinarian);
    this.setState({ veterinarian: newVeterinarian });
  }

  onNewLabTechSuccess = (newLabTech) => {
    this.state.labTechs.push(newLabTech);
    this.setState({ labTech: newLabTech });
  }

  onNewAnimalSuccess = (newAnimal) => {
    if (this.state.animals) {
      this.state.animals.push(newAnimal);
    }
    else {
      this.setState({ animals: [newAnimal] });
    }
    this.updateTestTypes(newAnimal);
  }

  updateTestTypes = (newAnimal) => {
    if (newAnimal == null) {
      this.setState({ testTypes: [], testType: null, animal: null, animalSpeciesId: null, animalAge: '' });
      return;
    }

    listTestTypes({ options: { where: { animalSpeciesId: newAnimal.getAnimalSpeciesId() } } }).then(({ results }) => {

      // Update each test type's associated inventory type statuses
      results.forEach(testType => {
        const inventoryTypes = [];
        if (testType.getJointChildTestTypes() != null && testType.getJointChildTestTypes().length > 0) {
          testType.getJointChildTestTypes()?.forEach(childTestType => {
            childTestType.getInventoryTypes().sort((a, b) => a.getZIndex() - b.getZIndex());
            childTestType.getInventoryTypes()?.forEach(inventoryType => inventoryTypes.push(inventoryType));
          });
        }
        else {
          testType.getInventoryTypes().sort((a, b) => a.getZIndex() - b.getZIndex());
          testType.getInventoryTypes()?.forEach(inventoryType => inventoryTypes.push(inventoryType));
        }
        inventoryTypes.forEach(inventoryType => {
          let associatedInventoryType = this.props.cartridgeInventoryTypes.find(cartridgeInventoryType => cartridgeInventoryType.getId() === inventoryType.getId());
          inventoryType.setStatus(associatedInventoryType?.getStatus() ?? INVENTORY_TYPE_STATUSES.UNKNOWN);
        });
      });

      let stateChanges = {};

      // Check if any of the test types have groups assigned to them; if so, switch to group mode
      if (results.some(testType => testType.getTestTypeGroup() != null)) {

        // Compile list of test type groups
        const testTypeGroups = [];
        results.forEach(testType => {
          if (testType.getTestTypeGroup() == null) return;
          if (testType.getTestTypeGroup() != null && !testTypeGroups.some(testTypeGroup => testTypeGroup.getId() === testType.getTestTypeGroup().getId())) {
            testTypeGroups.push(testType.getTestTypeGroup());
          }

          // Add the test type to the group
          const group = testTypeGroups.find(testTypeGroup => testTypeGroup.getId() === testType.getTestTypeGroup().getId());
          group.setTestTypes([...(group.getTestTypes() ?? []), testType]);
        });

        stateChanges = { selectionMode: 'TEST_TYPE_GROUP', testTypeGroups: testTypeGroups };
      }
      else {
        stateChanges = { selectionMode: 'TEST_TYPE', testTypes: results, testType: null };
      }

      this.setState({ ...stateChanges, animal: newAnimal, animalSpeciesId: newAnimal?.getAnimalSpeciesId(), animalAge: newAnimal != null ? (newAnimal.getAge() ?? '') : this.state.animalAge });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test types. Please try again.');
    });
  }

  toggleButtonStyle = ({ disabled, numItems = null }) => ({ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', opacity: disabled ? 0.8 : 1, cursor: disabled ? 'auto' : 'pointer', width: numItems != null ? `${Math.floor(100/numItems)}%` : 'auto' });

  render() {
    let showManageCartridgeButton = false;

    /** @type {({animal: import("@parasightsysteminc/companion-react").Animal} | {button:true} | {loading:true})[]} */
    const patientOptions = [];
    if ((this.state.animals?.length ?? 0) > 0)
      patientOptions.push(...this.state.animals.map(animal => ({ animal })));
    if (this.props.supportsPimsLookup && this.state.patientNameText?.length > 2)
      patientOptions.push({ button: true });

    if (this.state.redirectToHome) return <Redirect to='/' />;
    if (this.state.loadListsFailed) return <Redirect to='/tests/' />;
    if (this.props.isEllie) return (
      <form onSubmit={this.onSubmitEllie}>
        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Barcode</Typography>
        <TextField
          required
          autoFocus
          name='labdaqAccessionId'
          label='Lab Daq Accession ID'
          fullWidth
          style={{ marginTop: '0.5em' }}
          value={this.state.labdaqAccessionId}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Sample Quality Descriptor</Typography>
        <Typography variant='body1' style={{ color: 'slategrey' }}>Required*</Typography>
        <ToggleButtonGroup
          value={this.state.sampleQualityDescriptor}
          color='primary'
          exclusive
          style={{ width: '100%', minHeight: '200px', marginBottom: '20px', backgroundColor: LIGHTEST_GREEN }}
          onChange={(_, sampleQualityDescriptor) => {
            if (sampleQualityDescriptor == null) return;
            this.setState({ sampleQualityDescriptor });
          }}
          fullWidth
          disabled={this.state.isLoading}
          elevation={10}
        >
          {SAMPLE_QUALITY_DESCRIPTORS.map(descriptor => (
            <ToggleButton key={descriptor.value} value={descriptor.value} style={{ ...this.toggleButtonStyle({ disabled: false, numItems: 3 }), borderWidth: '5px 1px 5px 5px' }}>
              <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 30, color: '#454545', textDecoration: this.state.sampleQualityDescriptor === descriptor.value ? 'underline' : undefined }}>{descriptor.label}</Typography>
                <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20, color: '#454545', textDecoration: this.state.sampleQualityDescriptor === descriptor.value ? 'underline' : undefined }}>({descriptor.description})</Typography>
              </div>
              {this.state.sampleQualityDescriptor === descriptor.value ? <CheckCircleOutlineIcon style={{ color: '#454545', width: '40px', height: '40px' }} /> : <RadioButtonUncheckedIcon style={{ color: '#454545', width: '40px', height: '40px' }} />}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        {/* Button */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          <Button type='submit' isLoading={this.state.isLoading} style={{ width: '60%', height: '90px' }}>
            Start Test
          </Button>
        </div>
      </form>
    );
    return (
      <div>
        {this.state.pageIndex === 0 &&
        <>
          <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Practitioners Information</Typography>
          <Grid container spacing={0} direction='row-reverse'>
            <Grid item xs={1} md={3}>
              <div style={{ height: '3.5em' }}>
                <VeterinarianDialog onSuccess={this.onNewVeterinarianSuccess} disabled={this.state.isLoading} />
              </div>
              <div style={{ height: '4em' }}></div>

              {!this.state.veterinarianPerformingTest && (
                <div style={{ height: '3.5em', marginBottom: '0.5em' }}>
                  <LabTechDialog onSuccess={this.onNewLabTechSuccess} disabled={this.state.isLoading} />
                </div>
              )}

              <div style={{ height: '3.5em' }}>
                <AnimalDialog onSuccess={this.onNewAnimalSuccess} animalName={this.state.patientNameText} disabled={this.state.isLoading} />
              </div>
            </Grid>
          </Grid>
        </>}

        <form onSubmit={this.onSubmit}>
          {this.state.pageIndex === 0 &&
          <>
            <Grid container style={{ marginTop: this.state.veterinarianPerformingTest ? ('-11em') : ('-15em') }} spacing={0} direction='row'>
              <Grid item xs={11} md={9}>
                {/* Veterinarian - Select one with Autocomplete */}
                <Autocomplete
                  disablePortal
                  name="veterinarian"
                  required
                  options={this.state.veterinarians}
                  getOptionLabel={(option) => `${option?.getFirstName()} ${option?.getLastName()}, ${option?.getTitle()}`}
                  style={{ marginRight: '0.5em', marginBottom: '0.5em' }}
                  disabled={this.state.isLoading}
                  value={this.state.veterinarian}
                  onChange={this.onVeterinarianChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={true}
                      label='Veterinarian'
                      variant='filled'
                      disabled={this.state.isLoading}
                    />
                  )}
                />

                <div style={{ height: '3.5em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormControlLabel style={{ marginBottom: '0.5em' }}
                    control={<Checkbox checked={this.state.veterinarianPerformingTest} />}
                    onChange={() => this.setState({ veterinarianPerformingTest: !this.state.veterinarianPerformingTest })}
                    name='veterinarianPerformingTest'
                    label='Veterinarian is performing test' />
                </div>

                {!this.state.veterinarianPerformingTest && (
                  <>
                    {/* Lab Tech - Select one with Autocomplete */}
                    <Autocomplete
                      disablePortal
                      name="labTech"
                      options={this.state.labTechs}
                      getOptionLabel={(option) => `${option?.getFirstName()} ${option?.getLastName()}, ${option?.getTitle()}`}
                      style={{ marginRight: '0.5em', marginBottom: '0.5em' }}
                      disabled={this.state.isLoading}
                      value={this.state.labTech}
                      onChange={this.onLabTechChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label='Lab Tech'
                          variant='filled'
                          disabled={this.state.isLoading}
                        />
                      )}
                    />
                  </>
                )}

                {/* Animal - Select one with Autocomplete */}
                <div style={{ marginRight: '0.5em', display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
                  <Autocomplete
                    fullWidth
                    noOptionsText={this.state.animals ? 'No patients found' : 'Begin typing to search for a patient'}
                    onInputChange={this.handleInputChange}
                    disablePortal
                    name="animal"
                    options={patientOptions}
                    getOptionLabel={(option) => {
                      if (!option) return '';
                      if ('button' in option) return 'Create New Patient';
                      if ('loading' in option) return 'Loading...';
                      if ('animal' in option) return getAnimalName(option.animal);
                      if (Object.getPrototypeOf(option) === Animal.prototype) return getAnimalName(option);
                      return '';
                    }}
                    disabled={this.state.isLoading}
                    value={this.state.animal}
                    onChange={(_, val) => (val != null && 'animal' in val) ? this.onAnimalChange(val.animal) : this.onAnimalChange(null)}
                    filterOptions={(options) => options}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label='Patient'
                        variant='filled'
                        onChange={this.onPatientNameTextChange}
                        disabled={this.state.isLoading}
                      />
                    )}
                    renderOption={({ key, ...optionProps }, option) => {
                      if (option != null && 'button' in option) {
                        return (
                          <li
                            key='check-pims-button'
                            {...optionProps}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                            <Button
                              key={key}
                              onClick={
                                () => this.setState({ checkPimsDialogOpen: true })
                              }
                              style={{ width: '100%' }}
                              secondary
                              disabled={this.state.isLoading}
                            >
                              Check PIMS for missing options
                            </Button>
                          </li>);
                      }
                      else if (option != null && 'loading' in option) {
                        return (
                          <li
                            key='loading'
                            {...optionProps}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <CircularProgress />
                            </div>
                          </li>);
                      }
                      else {
                        const { animal } = option;
                        let name = getAnimalName(animal);
                        return (
                          <li key={animal.getId()} {...optionProps}>
                            <div>
                              {name}
                            </div>
                          </li>
                        );
                      }
                    }}
                  />
                </div>

              </Grid>
            </Grid>

            <TextField
              select
              required
              name='animalAge'
              label='Patient Age'
              fullWidth
              style={{ marginTop: '0.5em' }}
              value={this.state.animalAge}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
              {ANIMAL_AGES.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>

            <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Test Information</Typography>

            {/* Animal Species Selector */}
            {this.state.animal != null &&
            <>
              <Typography variant='button' style={{ color: 'slategrey' }}>Animal Species*</Typography>
              <ToggleButtonGroup
                value={this.state.animalSpeciesId === 6 ? 5 : this.state.animalSpeciesId /* Goat and sheep will use same image for now */}
                color='primary'
                exclusive
                style={{ marginBottom: '20px' }}
              >
                <ToggleButton value={1} disabled={this.state.animalSpeciesId !== 1} style={this.toggleButtonStyle({ disabled: this.state.animalSpeciesId !== 1, numItems: 5 })}>
                  <img style={{ width: '50%', height: '90%', objectFit: 'contain' }} src={DogPicture} />
                  <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>Dog</Typography>
                </ToggleButton>
                <ToggleButton value={2} disabled={this.state.animalSpeciesId !== 2} style={this.toggleButtonStyle({ disabled: this.state.animalSpeciesId !== 2, numItems: 5 })}>
                  <img style={{ width: '50%', height: '90%', objectFit: 'contain' }} src={CatPicture} />
                  <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>Cat</Typography>
                </ToggleButton>
                <ToggleButton value={3} disabled={this.state.animalSpeciesId !== 3} style={this.toggleButtonStyle({ disabled: this.state.animalSpeciesId !== 3, numItems: 5 })}>
                  <img style={{ width: '50%', height: '90%', objectFit: 'contain' }} src={HorsePicture} />
                  <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>Horse</Typography>
                </ToggleButton>
                <ToggleButton value={4} disabled={this.state.animalSpeciesId !== 4} style={this.toggleButtonStyle({ disabled: this.state.animalSpeciesId !== 4, numItems: 5 })}>
                  <img style={{ width: '50%', height: '90%', objectFit: 'contain' }} src={CattlePicture} />
                  <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>Cattle</Typography>
                </ToggleButton>
                <ToggleButton value={5} disabled={this.state.animalSpeciesId !== 5 && this.state.animalSpeciesId !== 6} style={this.toggleButtonStyle({ disabled: this.state.animalSpeciesId !== 5 && this.state.animalSpeciesId !== 6, numItems: 5 })}>
                  <img style={{ width: '50%', height: '90%', objectFit: 'contain' }} src={GoatPicture} />
                  <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>Sheep/Goat</Typography>
                </ToggleButton>
                <ToggleButton value={16} disabled={this.state.animalSpeciesId !== 16} style={this.toggleButtonStyle({ disabled: this.state.animalSpeciesId !== 16, numItems: 5 })}>
                  <img style={{ width: '50%', height: '90%', objectFit: 'contain' }} src={AvianPicture} />
                  <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>Avian</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </>}

            {/* Test Type - Select one with Autocomplete */}
            <Typography variant='button' style={{ color: 'slategrey' }}>Test Type*</Typography>
            {this.state.user?.getPractice()?.getIsDemo() && <Typography variant='body1' style={{ color: 'red', fontWeight: 'bold', marginBottom: '0.5em' }}>IN TRAINING MODE: CAPTURED IMAGES USED ARE FROM PREVIOUS SAMPLES</Typography>}
            {this.state.animal == null && <Typography variant='body1'>Please select a patient to view test type options.</Typography>}
            {this.state.animal != null && (this.state.selectionMode === 'TEST_TYPE' ? this.state.testTypes?.length === 0 : this.state.testTypeGroups?.length === 0) && <Typography variant='body1'>No test types available for this animal species.</Typography>}
            <ToggleButtonGroup
              color='primary' 
              value={this.state.selectedObject}
              exclusive
              onChange={(_, selectedObject) => {
                if (selectedObject == null) return;
                this.setState({ selectedObject });
              }}
              style={{ marginBottom: '10px' }}
              fullWidth
              orientation='vertical'
              disabled={this.state.isLoading}
            >
              {(this.state.selectionMode === 'TEST_TYPE' ? this.state.testTypes : this.state.testTypeGroups)?.sort((a, b) => a.getZIndex() > b.getZIndex() ? 1 : -1)?.map((selectionObject, index) => {
                let testTypes = this.state.selectionMode === 'TEST_TYPE' ? [selectionObject] : selectionObject.getTestTypes();

                // Check to ensure all cartridges are ready
                let cartridgesAreReady = true;
                let inventoryTypes = [];
                const hexColors = [];

                testTypes.forEach(testType => {
                  // Compile list of all inventory types needed
                  if (testType.getJointChildTestTypes() != null && testType.getJointChildTestTypes().length > 0) {
                    testType.getJointChildTestTypes()?.forEach(childTestType => {
                      childTestType.getInventoryTypes()?.forEach(inventoryType => {
                        if (!inventoryTypes.some(existingInventoryType => existingInventoryType.getId() === inventoryType.getId())) {
                          inventoryTypes.push(inventoryType);
                        }
                      });
                    });
                  }
                  else {
                    testType.getInventoryTypes()?.forEach(inventoryType => {
                      if (!inventoryTypes.some(existingInventoryType => existingInventoryType.getId() === inventoryType.getId())) {
                        inventoryTypes.push(inventoryType);
                      }
                    });
                  }

                  // Ensure that all cartridges are ready
                  inventoryTypes.forEach(inventoryType => {
                    const expirationDate = this.props.instrument.getExpirationDateForLine(inventoryType.getCartridgePosition());
                    if (inventoryType.getIsCartridge() && (!(inventoryType.isGood() || inventoryType.isLow()) || (expirationDate != null && expirationDate.diff(DateTime.now(), 'days').days < 0))) {
                      cartridgesAreReady = false;
                    }
                  });

                  // Get all hex colors
                  if (testType.getJointChildTestTypes() != null && testType.getJointChildTestTypes().length > 0) {
                    for (const jointChildTestType of testType.getJointChildTestTypes()) {
                      const color = jointChildTestType.getTestKit()?.getColorHex();
                      if (color != null && !hexColors.includes('#' + color)) hexColors.push('#' + color);
                    }
                  }
                  else {
                    const color = testType.getInventoryTypes()?.find(inventoryType => inventoryType.getIsTestKit())?.getColorHex();
                    if (color != null && !hexColors.includes('#' + color)) hexColors.push('#' + color);
                  }
                });

                return (
                  <StyledToggleButton backgroundColors={hexColors} key={index} value={selectionObject} style={{ ...this.toggleButtonStyle({ disabled: !cartridgesAreReady || selectionObject.getIsComingSoon() }) }} disabled={!cartridgesAreReady || selectionObject.getIsComingSoon()}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', textDecoration: this.state.selectedObject?.getId() === selectionObject.getId() ? 'underline' : '', padding: '5px 5px 5px 0px' }}>
                      <div style={{ width: '50px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {!cartridgesAreReady ? <ReportProblem style={{ color: '#454545', width: '40px', height: '40px' }} /> : (
                          selectionObject.getIsComingSoon() ? <AccessTimeFilledIcon style={{ color: '#454545', width: '40px', height: '40px' }} /> : (
                            this.state.selectedObject?.getId() === selectionObject.getId() ? <CheckCircleOutlineIcon style={{ color: '#454545', width: '40px', height: '40px' }} /> : <RadioButtonUncheckedIcon style={{ color: '#454545', width: '40px', height: '40px' }} />
                          )
                        )}
                      </div>

                      <div style={{ flexGrow: 1 }}>
                        <Typography variant='body1' style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 14, color: '#454545' }}>{selectionObject?.getName()}</Typography>
                        {selectionObject.getIsComingSoon() && (
                          <Typography variant='body1' style={{ textAlign: 'left', fontSize: 12, color: 'red' }}>Coming Soon</Typography>
                        )}

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                          {!selectionObject.getIsComingSoon() && inventoryTypes?.filter(inventoryType => {
                            const expirationDate = this.props.instrument.getExpirationDateForLine(inventoryType.getCartridgePosition());
                            const needsAttention = (!inventoryType.isGood() || (expirationDate != null && expirationDate.diff(DateTime.now(), 'days').days < 0)) && inventoryType.getIsCartridge();
                            if (needsAttention) showManageCartridgeButton = true;
                            return needsAttention;
                          })?.map((inventoryType, index) => {
                            let widthPercentage = inventoryTypes?.length != 0 ? Math.floor(100.0 / inventoryTypes.length + 0.5) : 0;
                            const expirationDate = this.props.instrument.getExpirationDateForLine(inventoryType.getCartridgePosition());

                            return (
                              <div key={index} style={{ width: widthPercentage + '%', maxWidth: '100px', margin: '1%' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                  <img src={inventoryType.getImageUrl()} style={{ width: '100%', objectFit: 'contain', backgroundColor: 'white', borderRadius: 15 /*, opacity: inventoryType.getIsCartridge() && !(inventoryType.isGood() || inventoryType.isLow()) ? '40%' : '100%'*/ }} />

                                  {/* For showing empty cartridge */}
                                  {inventoryType.getIsCartridge() &&
                                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {(!(inventoryType.isGood() || inventoryType.isLow() || inventoryType.isUnknown()) || (expirationDate != null && expirationDate.diff(DateTime.now(), 'days').days < 0)) && <BlockIcon style={{ color: 'red', width: '100%', height: '100%' }} />}
                                    {inventoryType.isLow() && <PriorityHighIcon style={{ color: 'goldenrod', width: '100%', height: '100%' }} />}
                                    {inventoryType.isUnknown() && <QuestionMarkIcon style={{ color: 'grey', width: '100%', height: '100%' }} />}
                                  </div>}
                                </div>

                                <Typography variant='button' style={{ fontWeight: 'bold', color: '#454545', backgroundColor: 'white', padding: 5, borderRadius: 4, marginTop: 5 }}>{expirationDate != null && expirationDate.diff(DateTime.now(), 'days').days < 0 ? 'Expired' : formatString(inventoryType.getStatus())}</Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </StyledToggleButton>
                );
              })}
            </ToggleButtonGroup>

            {/* Conditional Replace Cartridge Button */}
            {showManageCartridgeButton && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <Button secondary href={`/instruments/${cookies.getInstrumentSerialNumber()}/status`} style={{ backgroundColor: 'goldenrod', width: '100%', height: '60px' }}>
                  <ReportProblem style={{ marginRight: '10px' }} />
                  Manage Cartridges
                </Button>
              </div>
            )}

            <TextField
              name='sampleName'
              label='Sample Name (Optional)'
              fullWidth
              style={{ marginTop: '0.5em' }}
              value={this.state.sampleName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading} />

            <TextField
              name='comments'
              label='Comments (Optional)'
              fullWidth
              multiline
              rows={2}
              style={{ marginTop: '0.5em' }}
              value={this.state.comments}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading} />

            {/* Buttons */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
              {this.state.selectionMode === 'TEST_TYPE' ? (
                <Button type='submit' isLoading={this.state.isLoading} style={{ width: '60%', height: '90px' }}>
                  Start Test
                </Button>
              ) : (
                <Button style={{ width: '60%', height: '90px' }} onClick={() => {
                  this.setState({ pageIndex: 1 });

                  // Scroll to top of page
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }} disabled={this.state.selectedObject == null}>
                  Next
                </Button>
              )}
            </div>
          </>}

          {this.state.pageIndex === 1 &&
          <>

            <Button color='secondary' onClick={() => this.setState({ pageIndex: 0 })}>{'<  '} Back to Test Selection</Button>

            <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '0.5em' }}>Sample Quantity</Typography>
            <Typography variant='button' style={{ color: 'slategrey' }}>How much of the sample do you have for the test?*</Typography>
            <div>
              <ToggleButtonGroup
                value={this.state.isFecalLoop}
                color='primary'
                exclusive
                style={{ width: '100%', minHeight: '200px', marginBottom: '20px', backgroundColor: LIGHTEST_GREEN }}
                onChange={(_, isFecalLoop) => {
                  if (isFecalLoop == null) return;
                  this.setState({ isFecalLoop });
                }}
                fullWidth
                disabled={this.state.isLoading}
                elevation={10}
              >
                <ToggleButton value={true} style={{ ...this.toggleButtonStyle({ disabled: false, numItems: 2 }), borderWidth: '5px 1px 5px 5px' }}>
                  <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 30, color: '#454545', textDecoration: this.state.isFecalLoop === true ? 'underline' : undefined }}>Less than 1 gram</Typography>
                    <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 30, color: '#454545', textDecoration: this.state.isFecalLoop === true ? 'underline' : undefined }}>(Fecal Loop)</Typography>
                  </div>
                  {this.state.isFecalLoop ? <CheckCircleOutlineIcon style={{ color: '#454545', width: '40px', height: '40px' }} /> : <RadioButtonUncheckedIcon style={{ color: '#454545', width: '40px', height: '40px' }} />}
                </ToggleButton>
                <ToggleButton value={false} style={{ ...this.toggleButtonStyle({ disabled: false, numItems: 2 }), borderWidth: '5px 5px 5px 1px' }}>
                  <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 30, color: '#454545', textDecoration: this.state.isFecalLoop === false ? 'underline' : undefined }}>1 gram</Typography>
                  </div>
                  {this.state.isFecalLoop === false ? <CheckCircleOutlineIcon style={{ color: '#454545', width: '40px', height: '40px' }} /> : <RadioButtonUncheckedIcon style={{ color: '#454545', width: '40px', height: '40px' }} />}
                  
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {/* Button */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
              <Button type='submit' isLoading={this.state.isLoading} style={{ width: '60%', height: '90px' }}>
                Start Test
              </Button>
            </div>
          </>}
        </form>

        <SearchClientAndAnimalDialog
          open={this.state.checkPimsDialogOpen}
          onCancel={
            () => this.setState({ checkPimsDialogOpen: false })
          }
          onSuccess={
            (animal) => {
              this.setState({ checkPimsDialogOpen: false });
              this.onAnimalChange(animal);
            }}
        />

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '15px', marginTop: '40px' }}>
          <img src={SafetyImage} style={{ width: '500px' }} />
        </div>
      </div>
    );
  }
}

NewTestForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  test: PropTypes.object,
  onClose: PropTypes.func,
  cartridgeInventoryTypes: PropTypes.arrayOf(PropTypes.object),
  instrument: PropTypes.object.isRequired,
  onLoading: PropTypes.func.isRequired,
  supportsPimsLookup: PropTypes.bool.isRequired,
  isEllie: PropTypes.bool.isRequired
};

export default NewTestForm;

/**
 * @param {import("@parasightsysteminc/companion-react").Animal} animal
 */
function getAnimalName(animal) {
  let name = animal?.getName();
  const ownerFirstName = animal?.getOwnerFirstName();
  const ownerLastName = animal?.getOwnerLastName();
  if (ownerFirstName != null && ownerLastName != null) {
    name = `${name} (${ownerFirstName} ${ownerLastName})`;
  }
  else if (ownerFirstName != null) {
    name = `${name} (${ownerFirstName})`;
  }
  else if (ownerLastName != null) {
    name = `${name} (${ownerLastName})`;
  }
  name = `${name} - ${animal?.getAnimalSpecies()?.getName()}`;
  return name;
}

