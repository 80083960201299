import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// APIs
import { createAnimal, updateAnimal, listAnimalSpecies } from '../../api';

import { setError, setSuccess } from '../../alerts';

class AnimalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.animalName ?? '',
      animalSpeciesId: '',
      ownerFirstName: '',
      ownerLastName: '',
      ownerEmail: '',
      animalSpecies: []
    };
  }

  componentDidMount() {
    // Retrieve list of animal species options
    listAnimalSpecies().then(animalSpecies => {
      this.setState({ animalSpecies });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve animal species.');
    });

    // If this is an edit form, pre-fill the form
    if (this.props.animal != null) {
      this.setState({
        name: this.props.animal.getName() ?? '',
        animalSpeciesId: this.props.animal.getAnimalSpeciesId() ?? '',
        ownerFirstName: this.props.animal.getOwnerFirstName() ?? '',
        ownerLastName: this.props.animal.getOwnerLastName() ?? '',
        ownerEmail: this.props.animal.getOwnerEmail() ?? ''
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      name,
      animalSpeciesId,
      ownerFirstName,
      ownerLastName,
      ownerEmail
    } = this.state;

    // In Edit Mode
    if (this.props.animal != null) {
      updateAnimal({ id: this.props.animal.getId(), name, animalSpeciesId, ownerFirstName, ownerLastName, ownerEmail }).then(animal => {
        setSuccess('Successfully updated animal!');
        this.setState({ isLoading: false });
        this.props.onSuccess(animal);
      }).catch(error => {
        setError(error ?? 'Error: Unable to update animal. Please try again.');
        this.setState({ isLoading: false });
      });
    }

    // In Creation Mode
    else {
      createAnimal({ name, animalSpeciesId, ownerFirstName, ownerLastName, ownerEmail }).then(animal => {
        setSuccess('Successfully created animal!');
        this.setState({ isLoading: false });
        this.props.onSuccess(animal);
      }).catch(error => {
        setError(error ?? 'Error: Unable to create animal. Please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Patient Information</Typography>
        <>
          {/* Name */}
          <TextField
            required
            name='name'
            label='Name'
            value={this.state.name}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          {/* Species Selection - Select One */}
          <TextField
            required
            select
            name='animalSpeciesId'
            label='Species'
            style={{ width: '100%' }}
            value={this.state.animalSpeciesId}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}>
            {this.state.animalSpecies?.map((species, index) => (
              <MenuItem key={index} value={species.getId()}>{species.getName()}</MenuItem>
            ))}
          </TextField>
        </>

        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Owner Information</Typography>
        <>
          {/* Owner First Name */}
          <TextField
            required
            name='ownerFirstName'
            label='Owner First Name'
            value={this.state.ownerFirstName}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          {/* Owner Last Name */}
          <TextField
            required
            name='ownerLastName'
            label='Owner Last Name'
            value={this.state.ownerLastName}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />

          {/* Owner Email */}
          <TextField
            name='ownerEmail'
            label='Owner Email'
            value={this.state.ownerEmail}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />
        </>

        {/* Form Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {/* Cancel Button - Only shown if in edit mode */}
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '48%' }} onClick={this.props.onClose}>
            Cancel
          </Button>}

          {/* Submit Button */}
          <Button type='submit' isLoading={this.state.isLoading} style={{ width: '48%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

AnimalForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  animal: PropTypes.object,
  onClose: PropTypes.func,
  animalName: PropTypes.string
};

export default AnimalForm;
