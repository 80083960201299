import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';
import { MenuItem, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// APIs
import { listAnimalSpecies, createTreatment, deleteTreatment } from '../../api';

import { setError, setSuccess } from '../../alerts';

class TreatmentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      animalSpeciesId: '',
      speciesSelectorDisabled: false,
      animalSpecies: [],
      redirectToTreatments: false,
      isLoading: false
    };
  }

  componentDidMount() {
    listAnimalSpecies().then(animalSpecies => {
      this.setState({ animalSpecies });

      // Get the query param if there is one
      this.animalSpeciesId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.animalSpeciesId;
      this.animalSpeciesId = this.animalSpeciesId != null ? parseInt(this.animalSpeciesId) : null;
      if (this.animalSpeciesId != null && animalSpecies.find(animalSpecies => animalSpecies.getId() == this.animalSpeciesId) == null) {
        setError('Error: Invalid animal species selected');
      }
      else if (this.animalSpeciesId != null ) this.setState({ animalSpeciesId: this.animalSpeciesId });

      // If this is an edit form, pre-fill the form
      if (this.props.treatment != null) {
        this.setState({
          name: this.props.treatment.getName() ?? '',
          animalSpeciesId: this.props.treatment.getAnimalSpecies().getId() ?? ''
        });
      }
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve animal species list.');
      this.setState({ redirectToTreatments: true });
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    let {
      name,
      animalSpeciesId
    } = this.state;

    // In Edit Treatment Mode
    if (this.props.treatment != null) {
      deleteTreatment(this.props.treatment.getId()).then(() => {
        createTreatment({ name, animalSpeciesId }).then(treatment => {
          setSuccess('Successfully updated treatment!');
          this.setState({ isLoading: false });
          this.props.onSuccess(treatment);
        }).catch(error => {
          setError(error ?? 'Error: Unable to update treatment. Please try creating a new treatment instead.');
          this.setState({ isLoading: false });
        });
      }).catch(error => {
        setError(error ?? 'Error: Unable to update treatment. Please try again.');
        this.setState({ isLoading: false });
      });
    }

    // In Create New Treatment Mode
    else {
      createTreatment({ name, animalSpeciesId }).then(treatment => {
        setSuccess('Successfully created treatment!');
        this.setState({ isLoading: false });
        this.props.onSuccess(treatment);
      }).catch(error => {
        setError(error ?? 'Error: Unable to create treatment. Please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return this.state.redirectToTreatments ? <Redirect to='/treatments' /> : (
      <form onSubmit={this.onSubmit}>
        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Treatment Information</Typography>
        <>
          {/* Species Selection - Select One */}
          {this.animalSpeciesId == null ? (
            <TextField
              required
              select
              name='animalSpeciesId'
              label='Species'
              style={{ width: '100%' }}
              value={this.state.animalSpeciesId}
              onChange={this.onChange}
              variant='filled'
              disabled={this.animalSpeciesId != null}>
              {this.state.animalSpecies?.map((animalSpecies, index) => (
                <MenuItem key={index} value={animalSpecies.getId()}>{animalSpecies.getName()}</MenuItem>
              ))}
            </TextField>
          ) : (
            <Typography variant='body1' style={{ fontSize: 20, marginBottom: '0.5em' }}>Animal Species: {this.state.animalSpecies?.find(animalSpecies => animalSpecies.getId() === this.animalSpeciesId)?.getName()}</Typography>
          )}

          {/* Name */}
          <TextField
            required
            name='name'
            label='Name'
            value={this.state.name}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />
        </>

        {/* Form Buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          {/* Cancel Button - Only shown if in edit mode */}
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '48%' }} onClick={this.props.onClose}>
            Cancel
          </Button>}

          {/* Submit Button */}
          <Button type="submit" isLoading={this.state.isLoading} style={{ width: '48%' }}>
          Submit
          </Button>
        </div>
      </form>
    );
  }
}

TreatmentForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  treatment: PropTypes.object,
  location: PropTypes.object,
  onClose: PropTypes.func
};

export default withRouter(TreatmentForm);
