export function formatString(inputString) {
  // Split the string by underscores
  var words = inputString.split('_');

  // Capitalize each word and join them with spaces
  var formattedString = words.map(function(word) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  }).join(' ');

  return formattedString;
}