import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import qs from 'qs';
import cookies from '../cookies';

const HomeRedirect = ({ location }) => {

  // Check for Instrument Serial Number Query Param
  useEffect(() => {
    let instrumentSerialNumber = qs.parse(location?.search, { ignoreQueryPrefix: true })?.['instrument-serial-number'];

    // Set the Cookie
    if (instrumentSerialNumber != null) {
      cookies.setInstrumentSerialNumber(instrumentSerialNumber);
    }
  }, [location?.search]);

  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const isLoading = useSelector((state) => state?.auth?.isLoading);
  const passwordResetRequired = useSelector((state) => state?.auth?.passwordResetRequired);
  const isCookieSet = cookies.isInstrumentSet();

  if (isLoading) {
    return null;
  }
  else {
    if (isAuthenticated && passwordResetRequired) {
      return <Redirect to='/account/reset-password' />;
    }
    else if (isAuthenticated && isCookieSet) {
      return <Redirect to='/tests/new' />;
    }
    else if (isAuthenticated) {
      return <Redirect to='/tests' />;
    }
    else {
      return <Redirect to="/auth/login" />;
    }
  }
};

HomeRedirect.propTypes = {
  location: PropTypes.object.isRequired
};

class HomeRedirectWrapper extends React.Component {
  render() {
    return <HomeRedirect {...this.props} />;
  }
}

HomeRedirectWrapper.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(HomeRedirectWrapper);