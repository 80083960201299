import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Typography,  TextField } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { Logo } from '@parasightsysteminc/companion-react';

//APIs
import { forgotPasswordRequest } from '../../../../api';

//ALERTS
import { setError, setSuccess } from '../../../../alerts';

class ForgotPasswordRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isLoading: false,
      redirectToLogin: false
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    //send email to API
    forgotPasswordRequest({ email: this.state.email }).then(() => {
      setSuccess('Successfully requested password reset! Please check your email.');
      this.setState({ isLoading: false, redirectToLogin: true });
    }).catch(error => {
      setError(error ?? 'Error: Unable to request password reset. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return this.state.redirectToLogin ? <Redirect to='/auth/login' /> : (
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={Logo} style={{ width: '12em', marginTop: '2.5em' }}></img>
        </div>

        <form onSubmit={this.onSubmit}>
          <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Forgot Password Request</Typography>

          {/* Email */}
          <TextField
            required
            name='email'
            label='Email'
            onChange={this.onChange}
            disabled={this.state.isLoading}
            variant='filled'
            style={{
              width: '100%',
              marginBottom: '0.5em' }}>
          </TextField>

          {/* Submit */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type='submit'
              style={{ width: '48%', marginTop: '1em' }} isLoading={this.state.isLoading}>
              Submit
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}

ForgotPasswordRequest.propTypes = {
};

export default ForgotPasswordRequest;
