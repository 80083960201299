/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import { ADD_ALERT, REMOVE_ALERT, REMOVE_ALERT_FROM_MESSAGE, CLEAR_ERRORS, SET_BANNER, SET_UNDER_CONSTRUCTION, CLEAR_ALERTS, AlertSeverity } from '../actions/alertActions';

const initialState = {
  banner: null,
  underConstruction: null,
  alerts: []
};

const alerts = (state = initialState, action) => {
  let alerts = state.alerts ?? [];

  switch (action.type) {
    case ADD_ALERT:
      let { id, msg, severity } = action; // eslint-disable-line
      // Prevent more than 5 alerts to appear simultaneously or have duplicate errors
      return alerts.length < 5 && alerts.filter(alert => alert.msg === msg).length === 0 ? { ...state, alerts: [...alerts, { id, msg, severity }] } : state;
    case REMOVE_ALERT:
      return { ...state, alerts: alerts.filter(alert => alert.id !== action.id) };
    case REMOVE_ALERT_FROM_MESSAGE:
      return { ...state, alerts: alerts.filter(alert => alert.msg !== action.msg) };
    case CLEAR_ERRORS:
      return { ...state, alerts: alerts.filter(alert => alert.severity !== AlertSeverity.ERROR) };
    case SET_BANNER:
      return { ...state, banner: action.msg };
    case SET_UNDER_CONSTRUCTION:
      return { ...state, underConstruction: action.msg };
    case CLEAR_ALERTS:
      return { ...state, alerts: [] };
    default:
      return state;
  }
};

export default alerts;
