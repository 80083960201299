/*
 * Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
 * Licensed for non-distributable use
 */
import { v4 as uuidv4 } from 'uuid';

// Constants
const DEFAULT_TIMEOUT = 3000;
const EXTENDED_TIMEOUT = 60000;

/* -------------------------
   -------------------------
   ----- ACTION TYPES ------
   -------------------------
   ------------------------- */

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const REMOVE_ALERT_FROM_MESSAGE = 'REMOVE_ALERT_FROM_MESSAGE';
export const SET_BANNER = 'SET_BANNER';
export const SET_UNDER_CONSTRUCTION = 'SET_UNDER_CONSTRUCTION';
export const AlertSeverity = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
};

/* -------------------------
   -------------------------
   ---- ACTION OBJECTS -----
   -------------------------
   ------------------------- */

export const addAlert = (msg, severity = AlertSeverity.ERROR, link = null) => ({
  type: ADD_ALERT,
  id: uuidv4(),
  msg,
  link,
  severity
});

export const removeAlert = (id) => ({
  type: REMOVE_ALERT,
  id
});

export const removeAlertFromMessage = (msg) => ({
  type: REMOVE_ALERT_FROM_MESSAGE,
  msg
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

export const clearAlerts = () => ({
  type: CLEAR_ALERTS
});

export const setBanner = (msg) => ({
  type: SET_BANNER,
  msg
});

export const setUnderConstruction = (msg) => ({
  type: SET_UNDER_CONSTRUCTION,
  msg
});

/* -------------------------
   -------------------------
   -------- HELPERS --------
   -------------------------
   ------------------------- */

export const setSuccess = (dispatch, msg) => {
  dispatch(clearErrors());
  setAlert(dispatch, msg, AlertSeverity.SUCCESS, DEFAULT_TIMEOUT);
};

export const setError = (dispatch, msg) => {
  setAlert(dispatch, msg, AlertSeverity.ERROR, EXTENDED_TIMEOUT);
};

export const setInfo = (dispatch, msg) => {
  setAlert(dispatch, msg, AlertSeverity.INFO, null);
};

export const setAlert = (
  dispatch,
  msg,
  severity = AlertSeverity.ERROR,
  timeout = null,
  link = null
) => {
  window.scrollTo(0, 0);

  if (msg?.response?.data?.message != null && msg?.response?.data?.message !== '') msg = msg?.response?.data?.message;
  else if (msg?.message != null && msg?.message !== '') msg = msg.message;
  else if (msg?.error != null && msg?.error !== '') msg = msg.error;
  else if (msg == null || msg === '') {
    switch (severity) {
      case AlertSeverity.ERROR:
        msg = 'Error: something went wrong. Please try again later.';
        break;
      case AlertSeverity.WARNING:
        msg = 'Warning!';
        break;
      case AlertSeverity.INFO:
        msg = 'Info!';
        break;
      case AlertSeverity.SUCCESS:
        msg = 'Success!';
        break;
      default:
        msg = 'An unknown event occurred.';
    }
  }
  if (typeof msg !== 'string') msg = String(msg);

  // Add New Alert to App State
  const newAlert = addAlert(msg, severity, link);
  dispatch(newAlert);

  // Remove the New Alert After Timeout (3 sec default)
  if (timeout != null)
    setTimeout(() => dispatch(removeAlert(newAlert.id)), timeout);
};
