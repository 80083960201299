import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { Button, LineItem } from '@lexcelon/react-util';
import cookies from '../../cookies';

// API
import { getSelf, updateDemoMode } from '../../api';

import { PasswordChangeForm, DebugModeDialog } from '../../components';

// Alerts
import { setError, setSuccess } from '../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../alerts/confirm';

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      user: null,
      inPasswordEditMode: false,
      debugModeTriggerCount: 0,
      isDebugModeDialogOpen: false
    };
  }

  componentDidMount() {
    this.retrieveUser();
  }

  retrieveUser = () => {
    this.setState({ isLoading: true });
    getSelf().then(user => {
      this.setState({ isLoading: false, user });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve user info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  openPasswordEditMode = () => {
    this.setState({ inPasswordEditMode: true });
  }

  closePasswordEditMode = () => {
    this.setState({ inPasswordEditMode: false });
  }

  onPasswordChangeSuccess = () => {
    this.retrieveUser();
    setSuccess('Successfully changed password!');
    this.closePasswordEditMode();
  }

  triggerDebugMode = () => {
    // After 10 presses
    if (this.state.debugModeTriggerCount === 9) {
      // Turn off debug mode
      if (cookies.getInDebugMode()) {
        cookies.setInDebugMode(false);
        setSuccess('Debug mode off!');
        window?.location?.reload(false);
      }

      // Open debug password dialog
      else this.setState({ isDebugModeDialogOpen: true });

      this.setState({ debugModeTriggerCount: 0 });
    }
    else this.setState({ debugModeTriggerCount: this.state.debugModeTriggerCount + 1 });
  }

  toggleDemoMode = () => {

    confirm({
      title: `Are you sure you want to turn ${this.state.user.getPractice().getIsDemo() ? 'off' : 'on'} training mode?`,
      body: this.state.user.getPractice().getIsDemo() ? 'Turning off training mode will result in real scripts being used and real images being taken and analyzed.' : 'Training mode is for demonstrative purposes ONLY. Tests performed in training mode use an abbreviated cycle with results replicated from previous tests. The results from tests performed with training mode on will not be valid with the fecal sample you provide.',
      onConfirm: () => {
        startConfirmLoading();
        updateDemoMode({ isDemo: !this.state.user.getPractice().getIsDemo() }).then((practice) => {
          setSuccess(`Successfully turned ${this.state.user.getPractice().getIsDemo() ? 'off' : 'on'} training mode!`);
          this.state.user.setPractice(practice);
          this.setState({ user: this.state.user });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to deactivate instrument.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  render() {
    return (
      <Container>
        <DebugModeDialog isOpen={this.state.isDebugModeDialogOpen} onClose={() => this.setState({ isDebugModeDialogOpen: false })} />

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.5em' }}>Account Settings</Typography>

        <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Personal Information <button onClick={this.triggerDebugMode} style={{ backgroundColor: 'transparent', width: '20px', height: '20px', border: '0px solid transparent' }}></button></Typography>
        <>
          {/* Name */}
          <LineItem
            value={this.state.user?.getFirstName() + ' ' + this.state.user?.getLastName()}
            description='Name'
          />

          {/* Email */}
          <LineItem
            value={this.state.user?.getEmail()}
            description='Email'
          />

          {/* Practice Info */}
          <LineItem
            value={
              <Link to={{ pathname: '/account/practice', state: { backTo: { pathname: '/account', description: 'My Account' } } }}>{this.state.user?.getPractice()?.getName()}</Link>
            }
            description='Practice'
          />

        </>

        {/* Password */}
        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Password</Typography>
        {this.state.inPasswordEditMode ? (
          <PasswordChangeForm
            onSuccess={this.onPasswordChangeSuccess}
            onClose={this.closePasswordEditMode}
            user={this.state.user}
          />
        ) : (
          <Button secondary onClick={this.openPasswordEditMode}>Change Password</Button>
        )}

        {/* Training Mode Button */}
        <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Training Mode</Typography>
        {this.state.user != null &&
        <Button onClick={this.toggleDemoMode}>{this.state.user.getPractice().getIsDemo() ? 'Turn Off Training Mode' : 'Turn On Training Mode'}</Button>}

      </Container>
    );
  }
}

export default AccountSettings;
