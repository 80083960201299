import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

// Components
import { CircularProgress, Container, Typography } from '@mui/material';
import { VeterinarianForm } from '../../../components';
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';

// Api
import { getVeterinarian, deactivateVeterinarian } from '../../../api';

// Alerts
import { setError, setSuccess } from '../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

class Veterinarian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      veterinarian: null,
      inEditMode: false,
      redirectToVeterinarians: false
    };
  }

  componentDidMount() {
    this.retrieveVeterinarian();
  }

  retrieveVeterinarian = () => {
    // Retrieve the veterinarian id from the url
    const veterinarianId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getVeterinarian(veterinarianId).then(veterinarian => {
      this.setState({ isLoading: false, veterinarian });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve veterinarian info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  openEditMode = () => {
    this.setState({ inEditMode: true });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false });
  }

  onSuccess = () => {
    this.retrieveVeterinarian();
    this.closeEditMode();
  }

  deactivateConfirm = () => {
    confirm({
      title: `Are you sure you want to deactivate the veterinarian ${this.state.veterinarian.getFirstName()} ${this.state.veterinarian.getLastName()}?`,
      body: 'You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        deactivateVeterinarian(this.state.veterinarian?.getId()).then((veterinarian) => {
          setSuccess('Successfully deactivated veterinarian!');
          this.setState({ veterinarian, redirectToVeterinarians: true });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to deactivate veterinarian.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  render() {
    return this.state.redirectToVeterinarians ? (
      <Redirect to={this.props.location?.state?.backTo?.pathname ?? '/veterinarians'} />
    ) : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography
          variant='h1'
          style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
          Veterinarian{this.state.veterinarian != null ? ': ' + this.state.veterinarian.getLastName() : ''}
        </Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          this.state.inEditMode ? (
            <VeterinarianForm
              onSuccess={this.onSuccess}
              onClose={this.closeEditMode}
              veterinarian={this.state.veterinarian}
            />
          ) : (
            <>
              <>
                <LineItem
                  value={this.state.veterinarian?.getFirstName()}
                  description='First Name'
                />

                <LineItem
                  value={this.state.veterinarian?.getLastName()}
                  description='Last Name'
                />

                <LineItem
                  value={this.state.veterinarian?.getEmail()}
                  description='Email'
                />

                <LineItem
                  value={this.state.veterinarian?.getTitle()}
                  description='Title'
                />
              </>

              <div style={{ marginTop: '50px' }}>
                <Button secondary onClick={this.openEditMode}>Edit veterinarian</Button>
              </div>

              {(this.state.veterinarian?.getIsActive()) &&
                <div style={{ marginTop: '1.5em' }}>
                  <Button danger onClick={this.deactivateConfirm}>Suspend Veterinarian</Button>
                </div>
              }
            </>
          )
        )}
      </Container>
    );
  }
}

Veterinarian.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Veterinarian);
