import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';

// APIs
import { getTreatment, deleteTreatment } from '../../../api';

import { setError, setSuccess } from '../../../alerts';

// Components
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';
import { TreatmentForm } from '../../../components';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

class Treatment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      treatment: null,
      inEditMode: false,
      redirectToTreatments: false
    };
  }

  componentDidMount() {
    this.retrieveTreatment();
  }

  retrieveTreatment = () => {
    // Retrieve the treatment id from the url
    const treatmentId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getTreatment(treatmentId).then(treatment => {
      this.setState({ isLoading: false, treatment });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve treatment info. Please try again.');
      this.setState({ isLoading: false, redirectToTreatments: true });
    });
  }

  deleteConfirm = () => {
    confirm({
      title: `Are you sure you want to delete the treatment ${this.state.treatment.name}?`,
      body: 'You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        deleteTreatment(this.state.treatment.id).then(() => {
          setSuccess('Successfully deleted treatment!');
          this.setState({ redirectToTreatments: true });
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to delete treatment.');
          stopConfirmLoading();
          closeConfirm();
        });
      },
      danger: true
    });
  }

  openEditMode = () => {
    this.setState({ inEditMode: true });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false });
  }

  onEditSuccess = () => {
    this.setState({ redirectToTreatments: true });
  }

  render() {
    return this.state.redirectToTreatments ? <Redirect to={'/treatments/'} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Treatment{this.state.treatment != null ? ': ' + this.state.treatment.getName() : ''}</Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          this.state.inEditMode ? (
            <TreatmentForm
              treatment={this.state.treatment}
              onClose={this.closeEditMode}
              onSuccess={this.onEditSuccess}
            />
          ) : (
            <>
              <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Treatment Information</Typography>

              {this.state.treatment?.getImageUrl() != null && (
                <img src={this.state.treatment?.getImageUrl()} width="50%" height="auto" />
              )}

              <LineItem
                value={this.state.treatment?.getName()}
                description='Name'
              />

              <LineItem
                value={this.state.treatment?.getAnimalSpecies().getName()}
                description='Species'
              />

              {this.state.treatment?.getPracticeId() != null && (
                <>
                  <div style={{ marginTop: '50px' }}>
                    <Button secondary onClick={this.openEditMode}>Edit Treatment</Button>
                  </div>

                  <div style={{ marginTop: '0.5em' }}>
                    <Button danger onClick={this.deleteConfirm}>Delete Treatment</Button>
                  </div>
                </>
              )}
            </>
          )
        )}
      </Container>
    );
  }
}

Treatment.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Treatment);
