import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgress, Container, Typography } from '@mui/material';
import { fromSnakeToTitleCase } from '@lexcelon/js-util/lib/util';

// APIs
import { getAnimal } from '../../../api';

import { setError } from '../../../alerts';

// Components
import { BackToButton, Button, LineItem } from '@lexcelon/react-util';
import { AnimalForm } from '../../../components';

class Animal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      /** @type {import('@parasightsysteminc/companion-react').Animal} */
      animal: null,
      fromPims: false,
      inEditMode: false
    };
  }

  componentDidMount() {
    this.retrieveAnimal();
  }

  retrieveAnimal = () => {
    // Retrieve the animal id from the url
    const animalId = this.props.match?.params?.id;

    this.setState({ isLoading: true });
    getAnimal(animalId).then(animal => {
      this.setState({ isLoading: false, animal, fromPims: Boolean(animal.getSourceId()) });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve animal info. Please try again.');
      this.setState({ isLoading: false });
    });
  }

  openEditMode = () => {
    this.setState({ inEditMode: true });
  }

  closeEditMode = () => {
    this.setState({ inEditMode: false });
  }

  onSuccess = () => {
    this.retrieveAnimal();
    this.closeEditMode();
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Patient{this.state.animal != null ? ': ' + this.state.animal.getName() : ''}</Typography>

        {this.state.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          this.state.inEditMode ? (
            <AnimalForm
              onSuccess={this.onSuccess}
              onClose={this.closeEditMode}
              animal={this.state.animal}
            />
          ) : (
            <>
              <Typography variant='h2' style={{ marginBottom: '0.5em' }}>Patient Information</Typography>
              <>
                <LineItem
                  value={this.state.animal?.getName()}
                  description='Name'
                />

                <LineItem
                  value={fromSnakeToTitleCase(this.state.animal?.getAnimalSpecies()?.getName())}
                  description='Species'
                />
              </>

              <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Owner Information</Typography>
              <>
                <LineItem
                  value={this.state.animal?.getOwnerFirstName()}
                  description='Owner First Name'
                />

                <LineItem
                  value={this.state.animal?.getOwnerLastName()}
                  description='Owner Last Name'
                />

                <LineItem
                  value={this.state.animal?.getOwnerEmail()}
                  description='Owner Email'
                />
              </>

              <div style={{ marginTop: '50px' }}>
                <Button secondary onClick={this.openEditMode} disabled={this.state.fromPims}>Edit Patient</Button>
              </div>
              {this.state.fromPims && (
                <div style={{ marginTop: '10px' }}>
                  <Typography variant='body2' style={{ color: 'red' }}>This patient is synced from your PIMS and cannot be edited in Parasight.</Typography>
                </div>
              )}
            </>
          )
        )}
      </Container>
    );
  }
}

Animal.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Animal);
