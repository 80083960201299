import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { BackToButton } from '@lexcelon/react-util';

import { TreatmentForm } from '../../../components';

class CreateTreatment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToTreatmentId: null
    };
  }

  render() {
    return this.state.redirectToTreatmentId != null ? <Redirect to={'/treatments/' + this.state.redirectToTreatmentId} /> : (
      <Container style={{ paddingTop: '20px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Create Treatment</Typography>

        <TreatmentForm
          onSuccess={(treatment) => this.setState({ redirectToTreatmentId: treatment?.getId() })}
        />
      </Container>
    );
  }
}

CreateTreatment.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(CreateTreatment);
